import React, { useState, useEffect, useContext } from 'react';
import { useDataProvider, useLocale } from 'react-admin';
import GlobalLoading from '../components/GlobalLoading';

type DynamicOptionList = {
  id: string;
  name: string;
  default: boolean;
  parentListId: string | null;
  parentList: string | null;
}[];

type DynamicOptionTable = {
  [key: string]: DynamicOptionList;
};

export const DynamicOptionContext = React.createContext<{
  dynamicOptionTable?: DynamicOptionTable;
  updateDynamicOptionTable?: () => void;
}>({});

export const DynamicOptionProvider = (props: any) => {
  const [dynamicOptionTable, setDynamicOptionTable] = useState<DynamicOptionTable>();
  const [triggerDOTableUpdate] = useState<Date>();
  const dataProvider = useDataProvider();

  const updateDynamicOptionTable = () => {
    dataProvider
      .getList('DynamicOption', {
        filter: {
          preparedFilter: true,
          list: '',
          lang: props.lang,
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'id',
          order: 'asc',
        },
      })
      .then(({ data }) => {
        console.log('DynamicOption result: ', data);
        const tmpDOT: DynamicOptionTable = {};
        for (const option of data) {
          if (!tmpDOT[option.list]) {
            tmpDOT[option.list] = [];
          }
          tmpDOT[option.list].push({
            id: '' + option.id,
            name: option.value,
            default: option.defaultValue === 'Y',
            parentListId: option.parentListId,
            parentList: option.parentList,
          });
        }
        console.log('DynamicOption table: ', tmpDOT);
        setDynamicOptionTable(tmpDOT);
      });
  };

  useEffect(() => {
    updateDynamicOptionTable();
  }, []);

  if (!dynamicOptionTable) {
    return <GlobalLoading size={150} message={'Cargando...'} />;
  }

  return (
    <DynamicOptionContext.Provider value={{ dynamicOptionTable, updateDynamicOptionTable }}>
      {props.children}
    </DynamicOptionContext.Provider>
  );
};

export const useDynamicOptionList = (list: string): DynamicOptionList => {
  const { dynamicOptionTable } = useContext(DynamicOptionContext);

  return dynamicOptionTable ? dynamicOptionTable[list] || [] : [];
};

export const useUpdateDynamicOptionList = () => {
  const { updateDynamicOptionTable } = useContext(DynamicOptionContext);

  updateDynamicOptionTable && updateDynamicOptionTable();
};
