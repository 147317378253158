import React, { useState } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import Attachments from '../../AttachmentsAside';
import CustomForm from '../../CustomForm';

import { Actions, BurialUnitForm, burialUnitTransformFn } from './Common';

const BurialUnitEdit = (props: ResourceComponentPropsWithId) => {
  const [showAside, setShowAside] = useState(false);

  return (
    <Edit
      mutationMode="pessimistic"
      actions={<Actions onShowAttachmentsChange={setShowAside} />}
      aside={<Attachments open={showAside} />}
      {...props}
    >
      <CustomForm mutationType="update">
        <BurialUnitForm />
      </CustomForm>
    </Edit>
  );
};

export default BurialUnitEdit;
