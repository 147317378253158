export const downloadFile = async (url: string, fileName: string, authorization?: string) =>
  fetch(url, {
    mode: 'cors',
    headers: {
      ...(authorization ? { Authorization: authorization } : {}),
    },
  }).then((response) => {
    if (response.ok) {
      response.blob().then((pdf) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const downloadLink = document.createElement('a');
          downloadLink.href = reader.result as string;
          downloadLink.target = '_blank';
          downloadLink.download = fileName;
          downloadLink.click();
        });
        reader.readAsDataURL(pdf);
      });
    } else {
      console.log('RESPONSE', response);
      throw new Error(
        `Error status ${response.status}` + (response.statusText ? `: ${response.statusText}` : ''),
      );
    }
  });

export const uploadFile = async (
  url: string,
  file: File,
  companyId: string,
  attachmentId: string,
  authorization?: string,
) => {
  const payload = new FormData();
  payload.append('file', file);
  payload.append('companyId', companyId);
  payload.append('attachmentId', attachmentId);
  const res = await fetch(url, {
    method: 'post',
    mode: 'cors',
    body: payload,
    headers: {
      Accept: 'application/json',
      ...(authorization ? { Authorization: authorization } : {}),
    },
  });

  const data = await res.json();
  if (data.hasOwnProperty('message') && data.hasOwnProperty('name')) {
    let message = data.message;
    if (data.hasOwnProperty('extensions')) {
      message += ' Details: ' + data.extensions.join('. ');
    }
    const error = new Error(message);
    error.name = 'UploadError';
    throw error;
  }
};
