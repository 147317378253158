import React from 'react';
import {
  Datagrid,
  Edit,
  NumberField,
  ReferenceManyField,
  ResourceComponentPropsWithId,
  Show,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
} from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, RateForm } from './Common';

const RateEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <RateForm />
      </CustomForm>
    </Edit>
  );
};

// const RateEdit = (props: any) => (
//   <Show {...props}>
//     <SimpleForm>
//       <TextField source="description" />
//       <ReferenceManyField source="id" target="rateId" reference="ConceptRate" resource="Rate">
//         <Datagrid>
//           <TextField source="conceptId" />
//           <TextField source="periodId" />
//           <NumberField source="amount" />
//         </Datagrid>
//       </ReferenceManyField>
//     </SimpleForm>
//   </Show>
// );

export default RateEdit;
