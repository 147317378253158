import React from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, RoleForm } from './Common';

const RoleEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <RoleForm />
      </CustomForm>
    </Edit>
  );
};

export default RoleEdit;
