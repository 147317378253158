import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

const GlobalError = ({ message }: { message: string }) => (
  <div
    style={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box position="relative" display="inline-flex">
      <Card>
        <CardContent style={{ textAlign: 'center' }}>
          <ErrorTwoToneIcon style={{ fontSize: 200 }} />
          <Typography gutterBottom variant="h3" color="textSecondary">
            Error General Viatic
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Ha sido imposible contactar con el servidor, contacte con soporte técnico.
          </Typography>
          <br />
          <Typography variant="body2" color="textSecondary">
            Detalle: {message}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </div>
);

export default GlobalError;
