import React, { useState } from 'react';
import {
  BooleanInput,
  EditActionsProps,
  ListButton,
  required,
  SelectInput,
  TextInput,
  NumberInput,
  TopToolbar,
  minValue,
  maxValue,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const booleanFormatter = (v: any) => (v === 'Y' ? true : false);
const booleanParser = (v: any) => (v === true ? 'Y' : 'N');

const validateYear = [required(), minValue(2020), maxValue(2100)];
const validateCode = [required()];
const validateFormat = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const DocumentSeriesForm = ({ mutationType }: MutationTypeProps) => {
  const documentTypeList = useDynamicOptionList('L_DOCUMENT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="type"
            resource="DocumentSeries"
            choices={documentTypeList}
            defaultValue={(documentTypeList.find((ele) => ele.default) || documentTypeList[0]).id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <NumberInput source="year" resource="DocumentSeries" validate={validateYear} fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInput source="code" resource="DocumentSeries" validate={validateCode} fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInput
            source="format"
            resource="DocumentSeries"
            validate={validateFormat}
            fullWidth
          />
        </Grid>
        <Grid item container direction="row" alignItems="center" xs={12} md={3}>
          <BooleanInput
            source="defaultValue"
            resource="DocumentSeries"
            defaultValue={false}
            fullWidth
          />
        </Grid>
        {mutationType !== 'create' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="DocumentSeries"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}></Grid>
    </>
  );
};
