import React, { useState, useEffect, useCallback } from 'react';
import {
  AdminContext,
  AdminUI,
  DataProvider,
  I18nProvider,
  EditGuesser,
  ListGuesser,
  Resource,
} from 'react-admin';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BusinessIcon from '@material-ui/icons/Business';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DescriptionIcon from '@material-ui/icons/Description';
import EuroIcon from '@material-ui/icons/Euro';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PeopleIcon from '@material-ui/icons/People';
import PetsIcon from '@material-ui/icons/Pets';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SubjectIcon from '@material-ui/icons/Subject';

import { config } from '../helpers/config';

import { authProvider } from '../providers/AuthProvider';
import { i18nProviderFactory } from '../providers/I18nProvider';
import { GraphQLDataProvider } from '../providers/GraphQLDataProvider';

import MainLoginPage from './MainLoginPage';
import { ReactAdminLoginPage, withLoggedInStateChange } from './ReactAdminLoginPage';
import GlobalLoading from './GlobalLoading';
import GlobalError from './GlobalError';

import { AttachmentCreate, AttachmentEdit, AttachmentList } from './resources/Attachment';
import { BudgetCreate, BudgetEdit, BudgetList } from './resources/Budget';
import { BudgetLineCreate, BudgetLineEdit, BudgetLineList } from './resources/BudgetLine';
import { BurialUnitCreate, BurialUnitEdit, BurialUnitList } from './resources/BurialUnit';
import { CompanyCreate, CompanyEdit, CompanyList } from './resources/Company';
import { ConceptCreate, ConceptEdit, ConceptList } from './resources/Concept';
import { ConceptRateCreate, ConceptRateEdit, ConceptRateList } from './resources/ConceptRate';
import { CustomerCreate, CustomerEdit, CustomerList } from './resources/Customer';
import { DeceasedCreate, DeceasedEdit, DeceasedList } from './resources/Deceased';
import {
  DocumentSeriesCreate,
  DocumentSeriesEdit,
  DocumentSeriesList,
} from './resources/DocumentSeries';
import { InvoiceCreate, InvoiceEdit, InvoiceList } from './resources/Invoice';
import { InvoiceLineCreate, InvoiceLineEdit, InvoiceLineList } from './resources/InvoiceLine';
import { OvenCreate, OvenEdit, OvenList } from './resources/Oven';
import { PeriodCreate, PeriodEdit, PeriodList } from './resources/Period';
import { RateCreate, RateEdit, RateList } from './resources/Rate';
import { TriggerCreate, TriggerEdit, TriggerList } from './resources/Trigger';
import { RoleList, RoleEdit, RoleCreate } from './resources/Role';
import { WorkOrderList, WorkOrderEdit, WorkOrderCreate } from './resources/WorkOrder';
import {
  WorkOrderLineList,
  WorkOrderLineEdit,
  WorkOrderLineCreate,
} from './resources/WorkOrderLine';
import { UserList, UserEdit, UserCreate } from './resources/User';

import { DynamicOptionProvider } from '../contexts/DynamicOption';
import { CurrentPeriodProvider } from '../contexts/CurrentPeriod';

import viaticTheme from '../helpers/viatic-theme';
import CustomLayout from './CustomLayout';
import { SeriesProvider } from '../contexts/Series';

const App: React.FC = (props: any) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [globalError, setGlobalError] = useState<string>();
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  const [i18nProvider, setI18nProvider] = useState<I18nProvider>();

  useEffect(() => {
    console.log('App', props);
  }, [props]);

  useEffect(() => {
    const interval = window.setInterval(async () => {
      authProvider.checkAuth({}).catch(() => {
        console.log('Impossible to refresh session, destroying global Oauth2Manager.');
        setLoggedIn(false);
      });
    }, 10000);

    return () => {
      console.log('Clearing interval.');
      window.clearInterval(interval);
    };
  }, []);

  authProvider.setSessionExpiredEvent(useCallback(() => setLoggedIn(false), []));

  const handleLogin = async (params: any) => {
    // Request access token to authorization server.
    await authProvider.login(params);

    // Set state.
    setLoggedIn(true);

    const dataProvider = new GraphQLDataProvider(
      localStorage.getItem('selectedCompanyId') || config.defaultCompanyId,
      config.apiURL,
      config.oauth2ApiURL,
      authProvider,
    );
    dataProvider.isReady
      .then(() => setDataProvider(dataProvider))
      .catch((error) => setGlobalError(error.message));

    // Create i18nProvider.
    const language = (await authProvider.getIdentity!()).locale.slice(0, 2);
    console.log('Lang:', language);
    setI18nProvider(i18nProviderFactory(language));
  };

  if (!loggedIn) return <MainLoginPage onLogin={handleLogin} />;
  if (globalError) return <GlobalError message={globalError} />;
  if (!dataProvider) {
    return <GlobalLoading size={150} message={'Cargando...'} />;
  } else {
    console.log('Data provider', dataProvider);
    return (
      <AdminContext
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
      >
        <DynamicOptionProvider lang={i18nProvider?.getLocale()}>
          <CurrentPeriodProvider>
            <SeriesProvider>
              <AdminUI
                theme={viaticTheme}
                layout={CustomLayout}
                loginPage={withLoggedInStateChange(ReactAdminLoginPage, setLoggedIn)}
              >
                <Resource
                  name="Customer"
                  list={CustomerList}
                  edit={CustomerEdit}
                  create={CustomerCreate}
                  icon={PeopleIcon}
                />
                <Resource
                  name="Company"
                  list={CompanyList}
                  create={CompanyCreate}
                  edit={CompanyEdit}
                  icon={BusinessIcon}
                />
                <Resource
                  name="Oven"
                  list={OvenList}
                  edit={OvenEdit}
                  create={OvenCreate}
                  icon={FireplaceIcon}
                />
                <Resource
                  name="BurialUnit"
                  list={BurialUnitList}
                  edit={BurialUnitEdit}
                  create={BurialUnitCreate}
                  icon={FilterNoneIcon}
                />
                <Resource
                  name="DocumentSeries"
                  list={DocumentSeriesList}
                  edit={DocumentSeriesEdit}
                  create={DocumentSeriesCreate}
                  icon={SubjectIcon}
                />
                <Resource
                  name="Period"
                  list={PeriodList}
                  edit={PeriodEdit}
                  create={PeriodCreate}
                  icon={DateRangeIcon}
                />
                <Resource
                  name="Concept"
                  list={ConceptList}
                  edit={ConceptEdit}
                  create={ConceptCreate}
                  icon={FeaturedPlayListIcon}
                />
                <Resource
                  name="Rate"
                  list={RateList}
                  edit={RateEdit}
                  create={RateCreate}
                  icon={EuroIcon}
                />
                <Resource
                  name="ConceptRate"
                  edit={ConceptRateEdit}
                  create={ConceptRateCreate}
                  icon={EuroIcon}
                />
                <Resource
                  name="Deceased"
                  list={DeceasedList}
                  edit={DeceasedEdit}
                  create={DeceasedCreate}
                  icon={PetsIcon}
                />
                <Resource
                  name="Budget"
                  list={BudgetList}
                  edit={BudgetEdit}
                  create={BudgetCreate}
                  icon={ReceiptIcon}
                />
                <Resource
                  name="BudgetLine"
                  create={BudgetLineCreate}
                  edit={BudgetLineEdit}
                  icon={ReceiptIcon}
                />
                <Resource
                  name="WorkOrder"
                  list={WorkOrderList}
                  edit={WorkOrderEdit}
                  create={WorkOrderCreate}
                  icon={AssignmentIcon}
                />
                <Resource
                  name="WorkOrderLine"
                  create={WorkOrderLineCreate}
                  edit={WorkOrderLineEdit}
                  icon={AssignmentIcon}
                />
                <Resource
                  name="Invoice"
                  list={InvoiceList}
                  edit={InvoiceEdit}
                  create={InvoiceCreate}
                  icon={DescriptionIcon}
                />
                <Resource
                  name="InvoiceLine"
                  create={InvoiceLineCreate}
                  edit={InvoiceLineEdit}
                  icon={DescriptionIcon}
                />
                <Resource
                  name="User"
                  list={UserList}
                  edit={UserEdit}
                  create={UserCreate}
                  icon={AccountBoxIcon}
                />
                <Resource
                  name="Role"
                  list={RoleList}
                  edit={RoleEdit}
                  create={RoleCreate}
                  icon={RecentActorsIcon}
                />
                <Resource
                  name="Attachment"
                  list={AttachmentList}
                  edit={AttachmentEdit}
                  create={AttachmentCreate}
                  icon={AttachmentIcon}
                />
                <Resource
                  name="Trigger"
                  list={TriggerList}
                  edit={TriggerEdit}
                  create={TriggerCreate}
                  icon={AlarmOnIcon}
                />
              </AdminUI>
            </SeriesProvider>
          </CurrentPeriodProvider>
        </DynamicOptionProvider>
      </AdminContext>
    );
  }
};

export default App;
