import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Record, useTranslate } from 'react-admin';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.error.main,
  },
}));

interface SeriesDialogProps {
  open: boolean;
  title: string;
  documentSeriesList: Record[];
  onClose: (documentSeriesId?: string, date?: string) => void;
}
const SeriesDialog = ({ open, title, documentSeriesList, onClose }: SeriesDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [documentSeriesId, setDocumentSeriesId] = useState<string>();
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    const defaultSeriesId = documentSeriesList.find((s) => s.defaultValue)?.id;
    setDocumentSeriesId(defaultSeriesId ? defaultSeriesId + '' : undefined);
  }, [documentSeriesList]);

  const handleSeriesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDocumentSeriesId(event.target.value as string);
  };

  const handleClick = () => {
    if (!documentSeriesId || !date) {
      onClose();
    } else {
      onClose(documentSeriesId, date);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {/* <Autocomplete
                options={documentSeriesList}
                getOptionLabel={(ds: any) => `${ds.year} / ${ds.code} / ${ds.format}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('viatic.labels.series')}
                    variant="filled"
                  />
                )}
                value={documentSeries}
                onChange={(event: any, ds: Record | null) => setDocumentSeries(ds)}
                fullWidth
              /> */}
              <FormControl variant="filled" fullWidth>
                <InputLabel id="series-label">{translate('viatic.labels.series')}</InputLabel>
                <Select
                  labelId="series-label"
                  id="series-select"
                  value={documentSeriesId}
                  onChange={handleSeriesChange}
                >
                  {documentSeriesList.map((s) => (
                    <MenuItem value={s.id}>{`${s.year} / ${s.code}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={translate('viatic.labels.date')}
                type="date"
                variant="filled"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            {translate('viatic.labels.cancel')}
          </Button>
          <Button onClick={handleClick} color="primary" disabled={!documentSeriesId || !date}>
            {translate('viatic.labels.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SeriesDialog;
