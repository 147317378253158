import { gql } from '@apollo/client';

export const USER_GET_LIST = `
  query ($take: Int, $skip: Int, $orderBy: [UserOrderByInput!], $where: UserWhereInput) {
    total: aggregateUser {
      _count {
        _all
      }
    }
    users(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      email
      firstName
      lastName
      locale
      locked
      badTries
    }
  }
`;

export const USER_GET_ONE = `
  query ($where: UserWhereUniqueInput!) {
    user (where: $where) {
      id
      email
      firstName
      lastName
      locale
      locked
      badTries
      role {
        id
        name
      }
    }
  }
`;

export const USER_GET_MANY = ``;

export const USER_CREATE = `
  mutation ($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      email
      firstName
      lastName
      locale
      locked
      badTries
      role {
        id
        name
      }
    }
  }
`;

export const USER_UPDATE = `
  mutation ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      email
      firstName
      lastName
      locale
      locked
      badTries
      role {
        id
        name
      }
    }
  }
`;

export const USER_DELETE = `
  mutation ($id: String!) {
    deleteManyPassword( where: { userId: {equals: $id} }) {count}
    deleteUser(where: { id: $id }) {
      id
      email
      firstName
      lastName
      locale
      locked
      badTries
    }
  }
`;
