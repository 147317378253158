import React from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, BudgetForm } from './Common';

const BudgetEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <BudgetForm />
      </CustomForm>
    </Edit>
  );
};

export default BudgetEdit;
