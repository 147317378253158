import React, { useCallback, useContext } from 'react';
import { Edit, useMutation, useDataProvider } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, CompanyForm } from './Common';

const CompanyEdit: React.FC = (props) => {
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <CompanyForm />
      </CustomForm>
    </Edit>
  );
};

export default CompanyEdit;
