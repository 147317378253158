import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  EditActionsProps,
  email,
  FormDataConsumer,
  ListButton,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import CustomForm, { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const useStyles = makeStyles({
  option: {
    paddingTop: '0.8em',
  },
});

const validateRequired = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const ConceptForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const conceptTypeList = useDynamicOptionList('L_CONCEPT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');
  const languageList = useDynamicOptionList('L_LANGUAGE');

  const [localeList] = useState(languageList.map((lang) => lang.id));

  return (
    <>
      {mutationType === 'update' && (
        <Grid item>
          <TextInput source="description" resource="Concept" fullWidth disabled />
        </Grid>
      )}
      <Grid item container spacing={1}>
        <Grid item container spacing={1} xs={12} md={2}>
          <Grid item xs={12}>
            <SelectInput
              source="type"
              resource="Concept"
              choices={conceptTypeList}
              validate={validateRequired}
            />
          </Grid>
          {mutationType === 'update' && (
            <Grid item xs={12}>
              <SelectInput
                source="status"
                resource="Concept"
                choices={statusList}
                defaultValue={statusList.find((ele) => ele.default)!.id}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={1} xs={12} md={10}>
          <Grid item xs={12} md={3} alignItems="center">
            <BooleanInput
              source="requiresStartDate"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresEndDate"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresOriginBurialUnit"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresDestinationBurialUnit"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresAddress"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresOven"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresOriginCemetery"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <BooleanInput
              source="requiresDestinationCemetery"
              resource="Concept"
              className={classes.option}
              initialValue={false}
              fullwidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">{translate('viatic.texts.translations')}</Typography>
        <TranslatableInputs locales={localeList}>
          <TextInput
            source="i18nDescriptions"
            resource="Concept"
            validate={validateRequired}
            fullWidth
          />
        </TranslatableInputs>
      </Grid>
    </>
  );
};
