import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  BooleanInput,
  BooleanField,
  SelectField,
} from 'react-admin';

import { LOCALES } from './Common';

const UserFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="email|op=contains" label="resources.User.fields.email" alwaysOn />
      <TextInput source="firstName|op=contains" label="resources.User.fields.firstName" />
      <TextInput source="lastName|op=contains" label="resources.User.fields.lastName" />
      <BooleanInput source="locked|op=equals" label="resources.User.fields.locked" />
    </Filter>
  );
};

const UserList = (props: ListProps) => {
  console.log('UserList', props);

  return (
    <List {...props} filters={<UserFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <SelectField source="locale" choices={LOCALES} />
        <BooleanField source="locked" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
