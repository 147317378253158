import React from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, PeriodForm } from './Common';

const PeriodCreate: React.FC = (props) => {
  console.log(props);
  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm mutationType="create">
        <PeriodForm />
      </CustomForm>
    </Create>
  );
};

export default PeriodCreate;
