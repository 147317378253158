import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EmailField,
  SelectField,
  EditButton,
  FilterProps,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const CompanyFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <TextInput source="legalName|op=LIKE" label="resources.Company.fields.legalName" />
      <TextInput source="comercialName|op=LIKE" label="resources.Company.fields.comercialName" />
      <TextInput source="legalIdentifier|op=LIKE" label="resources.Company.fields.legalIdentifier" />
      <TextInput source="email|op=LIKE" label="resources.Company.fields.email" />
      <TextInput source="phone|op=LIKE" label="resources.Company.fields.phone" />
    </Filter>
  );
};

const CompanyList = (props: ListProps) => {
  const statusList = useDynamicOptionList('L_STATUS');
  console.log(statusList);

  return (
    <List {...props} filters={<CompanyFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="legalName" />
        <TextField source="comercialName" />
        <TextField source="legalIdentifier" />
        <EmailField source="email" />
        <TextField source="phone" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
