import { DataProvider, AuthProvider } from 'react-admin';

import { AbstractAdapter, ResourceOperation } from '../GQLAdapters/AbstractAdapter';
import { Oauth2ServerAdapter } from '../GQLAdapters/Oauth2ServerAdapter';
import { ViaticAdapter } from '../GQLAdapters/ViaticAdapter';

export class GraphQLDataProvider implements DataProvider {
  private adapters: AbstractAdapter[] = [];

  public isReady: Promise<any>;

  constructor(companyId: string, apiUrl: string, oauth2ApiUrl: string, authProvider: AuthProvider) {
    this.adapters.push(new ViaticAdapter(apiUrl, authProvider.getAuthorizationHeader, companyId));
    this.adapters.push(new Oauth2ServerAdapter(oauth2ApiUrl, authProvider.getAuthorizationHeader));

    this.isReady = Promise.all(this.adapters.map((adapter) => adapter.isReady));
  }

  private async callGQLAPI(raFetchType: ResourceOperation, resourceName: string, params: any) {
    // Find an adapter that can manage the request
    const api = this.adapters.find((adapter) => adapter.canHandleResource(resourceName));
    if (!api) {
      throw new Error(
        'GraphQLDataProvider: Impossible to find suitable adapter to resource:' + resourceName,
      );
    }

    // Build GraphQL query
    const { query, variables, parseResponse } = await api.buildGQLQuery(
      raFetchType,
      resourceName,
      params,
    );

    //Execute query and return response or manage error
    if (raFetchType.startsWith('GET')) {
      return api.client
        .query({ query, variables, fetchPolicy: 'no-cache' })
        .then((response) => parseResponse(response))
        .catch(api.handleError);
    }
    return api.client
      .mutate({ mutation: query, variables })
      .then((response) => parseResponse(response))
      .catch(api.handleError);
  }

  create(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('CREATE', resource, params);
  }

  delete(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('DELETE', resource, params);
  }

  deleteMany(resource: string, params: any): Promise<any> {
    throw new Error('GraphQLDataProvider: deleteMany method not implemented.');
  }

  getList(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('GET_LIST', resource, params);
  }

  getMany(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('GET_MANY', resource, params);
  }

  getManyReference(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('GET_LIST', resource, {
      ...params,
      filter: {
        ...params.filter,
        [params.target]: params.id,
      },
    });
  }

  getOne(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('GET_ONE', resource, params);
  }

  update(resource: string, params: any): Promise<any> {
    return this.callGQLAPI('UPDATE', resource, params);
  }

  updateMany(resource: string, params: any): Promise<any> {
    throw new Error('GraphQLDataProvider: updateMany method not implemented.');
  }

  async executeQuery(queryName: string, params: any) {
    // Find an adapter that can manage the request
    const api = this.adapters.find((adapter) => adapter.canHandleQuery(queryName));
    if (!api) {
      throw new Error(
        'GraphQLDataProvider: Impossible to find suitable adapter to query:' + queryName,
      );
    }

    // Build GraphQL query
    const { query, variables, type } = await api.buildGQLCustomQuery(queryName, params);
    console.log('EXECUTE_QUERY', query.loc?.source.body, variables, type);

    //Execute query and return response or manage error
    if (type === 'query') {
      return api.client
        .query({ query, variables, fetchPolicy: 'no-cache' })
        .then((response) => response.data)
        .catch(api.handleError);
    }
    return api.client
      .mutate({ mutation: query, variables })
      .then((response) => response.data)
      .catch(api.handleError);
  }

  getCompanyId(): string | undefined {
    let companyId = undefined;

    this.adapters.forEach((adapter) => {
      if (adapter instanceof ViaticAdapter) {
        companyId = adapter.getCompanyId();
      }
    });

    return companyId;
  }

  setCompanyId(companyId: string) {
    this.adapters.forEach((adapter) => {
      if (adapter instanceof ViaticAdapter) {
        adapter.setCompanyId(companyId);
      }
    });
  }
}
