import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EmailField,
  SelectField,
  EditButton,
  FilterProps,
  Filter,
  TextInput,
  SelectInput,
  DateField,
  NumberField,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const DeceasedFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const deceasedTypeList = useDynamicOptionList('L_DECEASED_TYPE');
  const sanitaryTypeList = useDynamicOptionList('L_SANITARY_TYPE');
  const deceasedOriginList = useDynamicOptionList('L_DECEASED_ORIGIN');
  const deceasedSituationList = useDynamicOptionList('L_DECEASED_SITUATION');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <TextInput source="chipCode|op=LIKE" label="resources.Deceased.fields.chipCode" />
      <SelectInput source="deceasedType" choices={deceasedTypeList} />
      <SelectInput source="sanitaryType" choices={sanitaryTypeList} />
      <SelectInput source="origin" choices={deceasedOriginList} />
      <SelectInput source="situation" choices={deceasedSituationList} />
    </Filter>
  );
};

const DeceasedList = (props: ListProps) => {
  const deceasedTypeList = useDynamicOptionList('L_DECEASED_TYPE');
  const sanitaryTypeList = useDynamicOptionList('L_SANITARY_TYPE');
  const deceasedOriginList = useDynamicOptionList('L_DECEASED_ORIGIN');
  const deceasedSituationList = useDynamicOptionList('L_DECEASED_SITUATION');
  const statusList = useDynamicOptionList('L_STATUS');
  console.log(statusList);

  return (
    <List {...props} filters={<DeceasedFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <TextField source="name" />
        <TextField source="chipCode" />
        <SelectField source="deceasedType" choices={deceasedTypeList} />
        <SelectField source="sanitaryType" choices={sanitaryTypeList} />
        <SelectField source="origin" choices={deceasedOriginList} />
        <SelectField source="situation" choices={deceasedSituationList} />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default DeceasedList;
