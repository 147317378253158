import React from 'react';
import {
  EditActionsProps,
  email,
  ListButton,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { MutationTypeProps } from '../../CustomForm';

const useStyles = makeStyles({
  address: {
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateLegalName = [required()];
const validateLegalIdentifier = [required()];
const validateEmail = [email()];
const validateLegalAddressAddress = [required()];
const validateLegalAddressPostalCode = [required()];
const validateLegalAddressCity = [required()];
const validateLegalAddressProvince = [required()];
const validateLegalAddressCountry = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const CompanyForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const statusList = useDynamicOptionList('L_STATUS');
  const identifierTypeList = useDynamicOptionList('L_IDENTIFIER_TYPE');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput source="legalName" resource="Company" fullWidth validate={validateLegalName} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="comercialName" resource="Company" fullWidth />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="identifierType"
            resource="Company"
            choices={identifierTypeList}
            defaultValue={identifierTypeList.find((ele) => ele.default)!.id}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInput
            source="legalIdentifier"
            resource="Company"
            fullWidth
            validate={validateLegalIdentifier}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextInput source="email" resource="Company" fullWidth validate={validateEmail} />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextInput source="phone" resource="Company" fullWidth />
        </Grid>

        {mutationType === 'update' && (
          <Grid item xs={12} md={4}>
            <SelectInput
              source="status"
              resource="Company"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
            />
          </Grid>
        )}
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={6} container direction="column">
          <Paper elevation={1} className={classes.address}>
            <Typography variant="h6">{translate('viatic.texts.legalAddress')}</Typography>
            <Grid item>
              <TextInput
                source="legalAddress.address"
                resource="Company"
                fullWidth
                validate={validateLegalAddressAddress}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.postalCode"
                  resource="Company"
                  fullWidth
                  validate={validateLegalAddressPostalCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.city"
                  resource="Company"
                  fullWidth
                  validate={validateLegalAddressCity}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.province"
                  resource="Company"
                  fullWidth
                  validate={validateLegalAddressProvince}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.country"
                  resource="Company"
                  fullWidth
                  validate={validateLegalAddressCountry}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} container direction="column">
          <Paper elevation={1} className={classes.address}>
            <Typography variant="h6">{translate('viatic.texts.postalAddress')}</Typography>
            <Grid item>
              <TextInput source="postalAddress.address" resource="Company" fullWidth />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.postalCode" resource="Company" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.city" resource="Company" fullWidth />
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.province" resource="Company" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.country" resource="Company" fullWidth />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
