import { AuthProvider, UserIdentity } from 'react-admin';
import jwtDecode from 'jwt-decode';

import { config } from '../helpers/config';

let oauth2Token: {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  date: Date;
} | null = null;

const tokenRequest = async (code: string, verifier: string): Promise<any> => {
  return await fetch(`${config.oauth2TokenEP}`, {
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: config.clientId,
      redirect_uri: config.appURL,
      code_verifier: verifier,
      scope: config.scope,
      code,
    }),
  }).then((response) => response.json());
};

const tokenRefresh = async (refreshToken: string, scope?: string): Promise<any> => {
  return await fetch(`${config.oauth2TokenEP}`, {
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: config.clientId,
      refresh_token: refreshToken,
    }),
  }).then((response) => response.json());
};

let onSessionExpired: () => void;

export const authProvider: AuthProvider = {
  login: async (params) => {
    console.log('AuthProvider: login', params);
    oauth2Token = {
      ...(await tokenRequest(params.code, params.verifier)),
      date: new Date(),
    };
    console.log('Token', oauth2Token);
  },
  checkError: async (error: Error) => {
    console.log('AuthProvider: checkError', error.name, '-', error.message);
    if (error.name === 'AuthenticationError') {
      onSessionExpired();
    }
  },
  checkAuth: async (params) => {
    console.log(
      'AuthProvider: checkAuth',
      Object.keys(params).length === 0 ? 'Empty params' : params,
    );

    if (!oauth2Token) throw new Error('viatic.errors.userNotLoggedIn');

    const expirationTS = oauth2Token.date.getTime() + oauth2Token.expires_in * 1000;
    if (expirationTS < Date.now()) throw new Error('viatic.errors.sessionExpired');
    const remainingTime = expirationTS - Date.now();
    if (Math.round(remainingTime / 1000 / 60) % 10 === 0)
      console.log('Token remaining: ', Math.round(remainingTime / 1000 / 60));
    if (expirationTS - Date.now() < 300 * 1000) {
      // Time to refresh token.
      oauth2Token = {
        ...(await tokenRefresh(oauth2Token.refresh_token, oauth2Token.scope)),
        date: new Date(),
      };
      console.log('Token refreshed', oauth2Token);
    }
  },
  logout: async () => {
    console.log('AuthProvider: logout');
    oauth2Token = null;
    window.location.href = `${config.oauth2LogoutEP}?redirect_uri=${encodeURIComponent(
      config.appURL,
    )}`;
  },
  getIdentity: async (): Promise<UserIdentity> => {
    console.log('AuthProvider: getIdentity');
    if (oauth2Token) {
      const { cid, email, locale } = jwtDecode<any>(oauth2Token.access_token);
      return { id: cid, email, locale };
    }
    throw new Error('User not logged in');
  },
  getPermissions: async (params) => {
    console.log('AuthProvider: getPermissions', params);
    if (oauth2Token) {
      const { scopes } = jwtDecode<any>(oauth2Token.access_token);
      console.log('Scopes', scopes);
      return scopes;
    }
    throw new Error('User not logged in');
  },
  getAuthorizationHeader: (): string => {
    console.log('AuthProvider: getAuthorizationHeader');
    if (oauth2Token) {
      return `${oauth2Token.token_type} ${oauth2Token.access_token}`;
    }

    console.log('AuthProvider: getAuthorizationHeader void response.');
    return '';
  },
  setSessionExpiredEvent: (fn: () => void) => {
    console.log('AuthProvider: setSessionExpiredEvent.');
    onSessionExpired = fn;
  },
};
