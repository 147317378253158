import React, { forwardRef } from 'react';
import { MenuItemLink, UserMenu, useTranslate } from 'react-admin';

import LockIcon from '@material-ui/icons/Lock';

import { config } from '../helpers/config';

const PasswordChangeMenu = forwardRef<any, any>(({ onClick }, ref) => {
  const translate = useTranslate();

  const handleClick = () => {
    window.location.href = `${config.oauth2PasswordChangeEP}?scope=${
      config.scope
    }&redirect_uri=${encodeURIComponent(config.appURL)}`;
  };

  return (
    <MenuItemLink
      ref={ref}
      to=""
      isActive={() => false}
      primaryText={translate('viatic.labels.changePassword')}
      leftIcon={<LockIcon />}
      onClick={handleClick} // close the menu on click
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <PasswordChangeMenu />
  </UserMenu>
);

export default CustomUserMenu;
