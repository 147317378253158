import React from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, ConceptRateForm } from './Common';

const redirect = (basePath: string, id: string, data: any) => `/Rate/${data.rate.id}`;

const ConceptRateCreate: React.FC = (props) => {
  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm mutationType="create" redirect={redirect}>
        <ConceptRateForm />
      </CustomForm>
    </Create>
  );
};

export default ConceptRateCreate;
