import React from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { ChevronLeft } from '@material-ui/icons';

const BackButton = () => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      startIcon={<ChevronLeft />}
      onClick={() => history.goBack()}
    >
      {translate('viatic.labels.back')}
    </Button>
  );
};

export default BackButton;
