import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Record, useTranslate } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.error.main,
  },
}));

interface WorkerDialogProps {
  open: boolean;
  userList: Record[];
  currentUserId?: string;
  onClose: (userId?: string) => void;
}
const WorkerDialog = ({ open, userList, currentUserId, onClose }: WorkerDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [user, setUser] = useState<Record | null>(null);

  const handleClick = () => {
    onClose(user && user.id !== currentUserId ? `${user.id}` : undefined);
    setUser(null);
  };

  const handleClose = () => {
    onClose();
    setUser(null);
  };

  useEffect(() => {
    if (open && (!user || (user && user.id !== currentUserId))) {
      setUser(userList.find((u) => u.id === currentUserId) || null);
    }
  }, [open, currentUserId, userList]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>{translate('viatic.texts.editWorker')}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={userList}
            getOptionLabel={(u) => `${u.firstName} ${u.lastName} - ${u.email}`}
            renderInput={(params) => (
              <TextField {...params} label={translate('viatic.texts.worker')} variant="outlined" />
            )}
            value={user}
            onChange={(event: any, user: Record | null) => setUser(user)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            {translate('viatic.labels.cancel')}
          </Button>
          <Button onClick={handleClick} color="primary" disabled={!user}>
            {translate('viatic.labels.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkerDialog;
