import React from 'react';
import { Edit, ResourceComponentPropsWithId, SimpleFormProps } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, OvenForm } from './Common';

const OvenEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <OvenForm />
      </CustomForm>
    </Edit>
  );
};

export default OvenEdit;
