import React, { useState } from 'react';
import {
  AutocompleteInput,
  EditActionsProps,
  ListButton,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import VisibilityToolbarButton from '../../VisibilityToolbarButton';

const validateCemetery = [required()];
const validateType = [required()];
const validateZone = [required()];
const validateYard = [required()];
const validateBlock = [required()];
const validateRow = [required()];
const validateNumber = [required()];

export type BurialUnitActionsProps = EditActionsProps & {
  onShowAttachmentsChange?: (show: boolean) => void;
};
export const Actions = ({ basePath, onShowAttachmentsChange }: BurialUnitActionsProps) => {
  const translate = useTranslate();

  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
      {onShowAttachmentsChange && (
        <VisibilityToolbarButton
          label={translate('viatic.texts.attachments')}
          initialVisibility={false}
          onVisibilityChange={onShowAttachmentsChange}
        />
      )}
    </TopToolbar>
  );
};

export const burialUnitTransformFn = (data: any) => {
  console.log('BurialUnitTransform', data);
  return {
    ...data,
    ownerId: data.owner?.id,
    beneficiaryId: data.beneficiary?.id,
  };
};

export const BurialUnitForm = ({ mutationType }: MutationTypeProps) => {
  const burialUnitTypeList = useDynamicOptionList('L_BURIAL_UNIT_TYPE');
  const cemeteryList = useDynamicOptionList('L_CEMETERY');
  const statusList = useDynamicOptionList('L_STATUS');
  const situationList = useDynamicOptionList('L_BURIAL_UNIT_SITUATION');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="cemetery"
            resource="BurialUnit"
            choices={cemeteryList}
            defaultValue={cemeteryList.find((ele) => ele.default)!.id}
            validate={validateCemetery}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="type"
            resource="BurialUnit"
            choices={burialUnitTypeList}
            defaultValue={burialUnitTypeList.find((ele) => ele.default)?.id}
            validate={validateType}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="situation"
            resource="BurialUnit"
            choices={situationList}
            defaultValue={situationList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        {mutationType !== 'create' && (
          <Grid item xs={12} md={3}>
            <SelectInput
              source="status"
              resource="BurialUnit"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <TextInput source="zone" resource="BurialUnit" validate={validateZone} fullWidth />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput source="yard" resource="BurialUnit" validate={validateYard} fullWidth />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput source="row" resource="BurialUnit" validate={validateRow} fullWidth />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput source="number" resource="BurialUnit" validate={validateNumber} fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            label="viatic.texts.owner"
            source="ownerId"
            reference="Customer"
            filterToQuery={(text: string) => ({ search: text })}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            label="viatic.texts.beneficiary"
            source="beneficiaryId"
            reference="Customer"
            filterToQuery={(text: string) => ({ search: text })}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>
    </>
  );
};
