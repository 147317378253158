import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const OvenFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const cemeteryList = useDynamicOptionList('L_CEMETERY');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <SelectInput source="cemetery" choices={cemeteryList} />
      <TextInput source="description|op=LIKE" label="resources.Oven.fields.description" />
    </Filter>
  );
};

const OvenList = (props: ListProps) => {
  const cemeteryList = useDynamicOptionList('L_CEMETERY');
  const statusList = useDynamicOptionList('L_STATUS');
  console.log(statusList);

  return (
    <List {...props} filters={<OvenFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <SelectField source="cemetery" choices={cemeteryList} />
        <TextField source="description" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default OvenList;
