import React, { useState } from 'react';
import {
  AutocompleteInput,
  EditActionsProps,
  email,
  FormDataConsumer,
  ListButton,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Button, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const useStyles = makeStyles({
  address: {
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateRequired = [required()];

export const Actions = ({ basePath }: EditActionsProps) => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

export const TriggerForm = ({ mutationType }: MutationTypeProps) => {
  const periodicityList = useDynamicOptionList('L_PERIODICITY');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="periodicity"
            resource="Trigger"
            choices={periodicityList}
            defaultValue={periodicityList.find((ele) => ele.default)!.id}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            label="viatic.labels.generatorConcept"
            source="generatorConceptId"
            reference="Concept"
            filterToQuery={(text: string) => null}
            validate={validateRequired}
          >
            <AutocompleteInput optionText="description" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            label="viatic.labels.generatedConcept"
            source="generatedConceptId"
            reference="Concept"
            filterToQuery={(text: string) => null}
            validate={validateRequired}
          >
            <AutocompleteInput optionText="description" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={2}>
          <NumberInput source="daysToFirstExecution" resource="Trigger" min={0} fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <NumberInput source="maxExecutions" resource="Trigger" min={0} fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={10}>
          <TextInput source="notes" resource="Trigger" multiline fullWidth />
        </Grid>
        {mutationType !== 'create' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="Trigger"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
