import React from 'react';

import Oauth2Login from './Oauth2Login';
import { config } from '../helpers/config';

type MainLoginPageProps = {
  onLogin: (params: any) => Promise<any>;
};

const MainLoginPage = ({ onLogin }: MainLoginPageProps) => {
  return (
    <Oauth2Login
      authorizationEndpoint={config.oauth2AuthEP}
      tokenEndpoint={config.oauth2TokenEP}
      clientId={config.clientId}
      redirectURI={config.appURL}
      scope={config.scope}
      onLogin={onLogin}
    />
  );
};

export default MainLoginPage;
