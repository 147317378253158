import * as React from 'react';
import { AppBar, DataProviderContext, useRedirect } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import BusinessIcon from '@material-ui/icons/Business';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MyUserMenu from './CustomUserMenu';

import { config } from '../helpers/config';

import logo from '../img/logo.png';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 20,
  },
  spacer: {
    flex: 0.5,
  },
  logo: {
    height: '40px',
  },
});

const CustomAppBar = (props: any) => {
  const classes = useStyles();

  const dataProvider = React.useContext(DataProviderContext);
  const redirect = useRedirect();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(
    localStorage.getItem('selectedCompanyId') || config.defaultCompanyId,
  );
  const [companyList] = React.useState(config.companyList || []);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectCompany = (id: string) => {
    handleClose();
    if (id !== selectedCompanyId) {
      localStorage.setItem('selectedCompanyId', id);
      dataProvider.setCompanyId(id);
      setSelectedCompanyId(id);
      redirect('/');
    }
  };

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />

      <Tooltip title="Empresa seleccionada">
        <Button
          onClick={handleMenu}
          startIcon={<BusinessIcon />}
          endIcon={<ExpandMoreIcon />}
          color="inherit"
        >
          <span>{companyList.find((company: any) => company.id === selectedCompanyId)?.name}</span>
        </Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {companyList.map((company: any) => (
          <MenuItem
            key={company.id}
            selected={company.id === selectedCompanyId}
            onClick={() => selectCompany(company.id)}
          >
            {company.name}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

export default CustomAppBar;
