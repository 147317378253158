import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const DocumentSeriesFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const documentTypeList = useDynamicOptionList('L_DOCUMENT_TYPE');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <SelectInput source="type" choices={documentTypeList} />
      <TextInput source="year|op=LIKE" label="resources.DocumentSeries.fields.year" />
      <TextInput source="code|op=LIKE" label="resources.DocumentSeries.fields.code" />
      <BooleanInput source="defaultValue" label="resources.DocumentSeries.fields.defaultValue" />
      <TextInput source="format|op=LIKE" label="resources.DocumentSeries.fields.format" />
    </Filter>
  );
};

const DocumentSeriesList = (props: ListProps) => {
  const documentTypeList = useDynamicOptionList('L_DOCUMENT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <List {...props} filters={<DocumentSeriesFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <SelectField source="type" choices={documentTypeList} />
        <TextField source="year" />
        <TextField source="code" />
        <BooleanField source="defaultValue" />
        <TextField source="format" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default DocumentSeriesList;
