import React, { useEffect, useState } from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, WorkOrderLineForm } from './Common';

const WorkOrderLineCreate: React.FC = (props: any) => {
  const [workOrderId, setWorkOrderId] = useState<string>();

  useEffect(() => {
    setWorkOrderId(props.location.state.record.workOrderId);
  }, []);

  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm
        mutationType="create"
        redirect={() => (workOrderId ? `/WorkOrder/${workOrderId}` : '/WorkOrder')}
      >
        <WorkOrderLineForm />
      </CustomForm>
    </Create>
  );
};

export default WorkOrderLineCreate;
