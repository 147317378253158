import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export interface VisibilityProps {
  initialVisibility?: boolean;
  onVisibilityChange: (visible: boolean) => void;
}

export type VisibilityToolbarButtonProps = {
  label: string;
} & VisibilityProps;

const VisibilityToolbarButton = ({
  label,
  initialVisibility,
  onVisibilityChange,
}: VisibilityToolbarButtonProps) => {
  const [visible, setVisible] = useState(initialVisibility || false);

  const handleButtonClick = () => {
    onVisibilityChange(!visible);
    setVisible(!visible);
  };

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      startIcon={visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      onClick={handleButtonClick}
    >
      {label}
    </Button>
  );
};

export default VisibilityToolbarButton;
