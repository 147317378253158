import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DataProviderContext,
  DateInput,
  DeleteButton,
  DeleteButtonProps,
  EditActionsProps,
  EditButton,
  EditButtonProps,
  Filter,
  FormDataConsumer,
  Identifier,
  List,
  ListButton,
  NumberInput,
  Record,
  ReferenceInput,
  ReferenceManyField,
  refreshSaga,
  required,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  translate,
  useAuthProvider,
  useDataProvider,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { useFormState } from 'react-final-form';

import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';

import { config } from '../../../helpers/config';
import CustomForm, { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { Link } from 'react-router-dom';
import DeleteListItem from '../../DeleteListItem';
import { downloadFile } from '../../../helpers/transfer-file';
import SeriesDialog from './SeriesDialog';
import { GraphQLDataProvider } from '../../../providers/GraphQLDataProvider';
import { useSeriesList } from '../../../contexts/Series';

const useStyles = makeStyles((theme) => ({
  rates: {
    width: '100%',
    margin: '0.3em',
    padding: '0.5em',
  },
  deleteConceptRateButton: {
    color: theme.palette.error.main,
  },
}));

const validateRequired = [required()];

export const Actions = ({ basePath, data }: EditActionsProps) => {
  const authProvider = useAuthProvider();
  const dataProvider = useContext(DataProviderContext);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();
  const workOrderSeriesList = useSeriesList('WORKORDER');
  const invoiceSeriesList = useSeriesList('INVOICE');

  const [budget2WorkOrderOpen, setBudget2WorkOrderOpen] = useState(false);
  const [budget2InvoiceOpen, setBudget2InvoiceOpen] = useState(false);

  const handleBudgetAcceptClick = () => {
    (dataProvider as GraphQLDataProvider)
      .executeQuery('budgetAccept', {
        input: {
          id: data!.id,
        },
      })
      .then((response) => {
        console.log('BUDGET ACCEPTED', response);
        notify('viatic.texts.budgetAccepted', {
          type: 'success',
        });
        refresh();
      })
      .catch((error) => {
        console.error('ERROR in budgetToWorkOrder', error);
        notify(error.message, {
          type: 'error',
        });
      });
  };

  const handleBudget2WorkOrderClick = () => {
    setBudget2WorkOrderOpen(true);
  };

  const handleBudget2InvoiceClick = () => {
    setBudget2InvoiceOpen(true);
  };

  const handleBudget2WorkOrderClose = (documentSeriesId?: string, date?: string) => {
    setBudget2WorkOrderOpen(false);
    if (documentSeriesId && date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('budgetToWorkOrder', {
          input: {
            id: data!.id,
            documentSerieId: documentSeriesId,
            date,
          },
        })
        .then((response) => {
          console.log('WORKER_ORDER CREATED', response);
          redirect('edit', '/WorkOrder', response.budgetToWorkOrder.id);
        })
        .catch((error) => {
          console.error('ERROR in budgetToWorkOrder', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleBudget2InvoiceClose = (documentSeriesId?: string, date?: string) => {
    setBudget2InvoiceOpen(false);
    if (documentSeriesId && date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('budgetToInvoice', {
          input: {
            id: data!.id,
            documentSerieId: documentSeriesId,
            date,
          },
        })
        .then((response) => {
          console.log('INVOICE CREATED', response);
          redirect('edit', '/Invoice', response.budgetToInvoice.id);
        })
        .catch((error) => {
          console.error('ERROR in budgetToInvoice', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleDownloadClick = () => {
    downloadFile(
      `${config.documentsBaseURL.BUDGET}/${data!.document.path}`,
      `${data!.format}.pdf`,
      authProvider.getAuthorizationHeader(),
    ).catch((error) => {
      notify((error as Error).message, {
        type: 'error',
      });
    });
  };

  return (
    <>
      <TopToolbar>
        <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<CheckIcon />}
          onClick={handleBudgetAcceptClick}
          disabled={!data || !data.budgetLineList || data.situation !== 'INITIATED'}
        >
          {translate('viatic.labels.budgetAccept')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<AssignmentIcon />}
          onClick={handleBudget2WorkOrderClick}
          disabled={!data || !data.budgetLineList || data.situation === 'CLOSED'}
        >
          {translate('viatic.labels.budget2WorkOrder')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<DescriptionIcon />}
          onClick={handleBudget2InvoiceClick}
          disabled={!data || !data.budgetLineList || data.situation === 'CLOSED'}
        >
          {translate('viatic.labels.budget2Invoice')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleDownloadClick}
          disabled={!data || !data.document}
        >
          PDF
        </Button>
      </TopToolbar>
      <SeriesDialog
        open={budget2WorkOrderOpen}
        title={translate('viatic.texts.budget2WorkOrder')}
        onClose={handleBudget2WorkOrderClose}
        documentSeriesList={workOrderSeriesList}
      />
      <SeriesDialog
        open={budget2InvoiceOpen}
        title={translate('viatic.texts.budget2Invoice')}
        onClose={handleBudget2InvoiceClose}
        documentSeriesList={invoiceSeriesList}
      />
    </>
  );
};

export const BudgetForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { values } = useFormState();
  const budgetSeriesList = useSeriesList('BUDGET');
  const statusList = useDynamicOptionList('L_STATUS');
  const paymentMethodList = useDynamicOptionList('L_PAYMENT_METHOD');
  const paymentInstrumentList = useDynamicOptionList('L_PAYMENT_INSTRUMENT');
  const situationList = useDynamicOptionList('L_BUDGET_SITUATION');

  const deleteBudgetLine = async (budgetLineId?: Identifier) => {
    if (budgetLineId) {
      try {
        await dataProvider.delete('BudgetLine', { id: budgetLineId });
        refresh();
      } catch (error) {
        notify((error as Error).message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.Budget.fields.customer"
              source="customerId"
              reference="Customer"
              filterToQuery={(text: string) => ({ search: text })}
              validate={validateRequired}
            >
              <AutocompleteInput
                optionText={(r: any) =>
                  r && `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
                }
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Customer/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/Budget',
                    state: { record: { ...values } },
                    fieldName: 'customerId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.Budget.fields.deceased"
              source="deceasedId"
              reference="Deceased"
              filterToQuery={(text: string) => ({ search: text })}
              allowEmpty={true}
            >
              <AutocompleteInput
                optionText={(r: any) => (r && r.id ? `${r.name} - ${r.deathDate}` : '')}
                allowEmpty={true}
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Deceased/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/Budget',
                    state: { record: { ...values } },
                    fieldName: 'deceasedId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="paymentMethod"
            resource="Budget"
            choices={paymentMethodList}
            defaultValue={paymentMethodList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="paymentInstrument"
            resource="Budget"
            choices={paymentInstrumentList}
            defaultValue={paymentInstrumentList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            label="resources.Budget.fields.documentSerie"
            source="documentSerieId"
            optionText={(r: any) => `${r.year} / ${r.code}`}
            choices={budgetSeriesList}
            defaultValue={budgetSeriesList.find((ele) => ele.defaultValue)!.id}
            disabled={mutationType === 'update'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateInput source="date" resource="Budget" fullWidth />
        </Grid>
      </Grid>
      {mutationType === 'update' && (
        <>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={2}>
              <TextInput source="format" resource="Budget" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="subtotal" resource="Budget" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="total" resource="Budget" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextInput source="currency" resource="Budget" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="situation"
                resource="Budget"
                choices={situationList}
                defaultValue={situationList.find((ele) => ele.default)!.id}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="status"
                resource="Budget"
                choices={statusList}
                defaultValue={statusList.find((ele) => ele.default)!.id}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <Grid item container spacing={1}>
                <Paper elevation={1} className={classes.rates}>
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={11}>
                      <Typography variant="h6">{translate('viatic.labels.lines')}</Typography>
                    </Grid>
                    <Grid item xs={1} container direction="row-reverse">
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to={{
                          pathname: '/BudgetLine/create',
                          state: {
                            record: {
                              budgetId: formData.id,
                            },
                          },
                        }}
                      >
                        {translate('ra.action.create')}
                      </Button>
                    </Grid>
                  </Grid>
                  <ReferenceManyField
                    label="viatic.labels.lines"
                    target="budgetId"
                    reference="BudgetLine"
                    sort={{ field: 'number', order: 'ASC' }}
                    perPage={10000}
                  >
                    <Datagrid>
                      {/* <TextField
                          source="period.description"
                          label={translate('resources.Period.name', { smart_count: 1 })}
                        /> */}

                      <TextField
                        source="description"
                        resource="BudgetLine"
                        label={translate('resources.Concept.name', { smart_count: 1 })}
                      />
                      <TextField source="amountUnit" resource="BudgetLine" />
                      <TextField source="units" resource="BudgetLine" />
                      <TextField source="amountSubtotal" resource="BudgetLine" />
                      <TextField source="amountTax" resource="BudgetLine" />
                      <TextField source="amountTotal" resource="BudgetLine" />
                      <EditButton />
                      <DeleteListItem deleteItem={deleteBudgetLine} />
                    </Datagrid>
                  </ReferenceManyField>
                </Paper>
              </Grid>
            )}
          </FormDataConsumer>
        </>
      )}
    </>
  );
};
