import {
  ApolloClient,
  ApolloError,
  createHttpLink,
  gql,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpError } from 'react-admin';

import {
  AbstractAdapter,
  CustomQueryMatchingConfiguration,
  ResourceOperation,
} from './AbstractAdapter';
import {
  ROLE_CREATE,
  ROLE_DELETE,
  ROLE_GET_LIST,
  ROLE_GET_MANY,
  ROLE_GET_ONE,
  ROLE_UPDATE,
} from './Oauth2ServerQueries/role';
import {
  SCOPE_CREATE,
  SCOPE_DELETE,
  SCOPE_GET_LIST,
  SCOPE_GET_MANY,
  SCOPE_GET_ONE,
  SCOPE_UPDATE,
} from './Oauth2ServerQueries/scope';
import {
  USER_CREATE,
  USER_DELETE,
  USER_GET_LIST,
  USER_GET_MANY,
  USER_GET_ONE,
  USER_UPDATE,
} from './Oauth2ServerQueries/user';
import ValidationError from '../errors/ValidationError';

type ResourceConfiguration = {
  [operation in ResourceOperation]: { queryName: string; queryText: string };
};
type ResourceMatchingConfiguration = {
  [resource: string]: ResourceConfiguration;
};

const MATCHING_CONFIG: ResourceMatchingConfiguration = {
  Role: {
    GET_LIST: { queryName: 'roles', queryText: ROLE_GET_LIST },
    GET_ONE: { queryName: 'role', queryText: ROLE_GET_ONE },
    GET_MANY: { queryName: 'roles', queryText: ROLE_GET_MANY },
    CREATE: { queryName: 'createRole', queryText: ROLE_CREATE },
    UPDATE: { queryName: 'updateRole', queryText: ROLE_UPDATE },
    DELETE: { queryName: 'deleteRole', queryText: ROLE_DELETE },
  },
  Scope: {
    GET_LIST: { queryName: 'scopes', queryText: SCOPE_GET_LIST },
    GET_ONE: { queryName: 'scope', queryText: SCOPE_GET_ONE },
    GET_MANY: { queryName: 'scopes', queryText: SCOPE_GET_MANY },
    CREATE: { queryName: 'createScope', queryText: SCOPE_CREATE },
    UPDATE: { queryName: 'updateScope', queryText: SCOPE_UPDATE },
    DELETE: { queryName: 'deleteScope', queryText: SCOPE_DELETE },
  },
  User: {
    GET_LIST: { queryName: 'users', queryText: USER_GET_LIST },
    GET_ONE: { queryName: 'user', queryText: USER_GET_ONE },
    GET_MANY: { queryName: 'users', queryText: USER_GET_MANY },
    CREATE: { queryName: 'createUser', queryText: USER_CREATE },
    UPDATE: { queryName: 'updateUser', queryText: USER_UPDATE },
    DELETE: { queryName: 'deleteUser', queryText: USER_DELETE },
  },
};

const CUSTOM_QUERIES_CONFIG: CustomQueryMatchingConfiguration = {};

const IGNORED_FIELDS = [
  'createdAt',
  'createdBy',
  'deletedAt',
  'deletedBy',
  'updatedAt',
  'updatedBy',
  '__typename',
];

// Create code to call Oauth2 server API.
export class Oauth2ServerAdapter implements AbstractAdapter {
  public client: ApolloClient<unknown>;
  public isReady: Promise<any>;

  constructor(apiUrl: string, getAuthorizationHeader: () => string | undefined) {
    // Create Apollo client.
    const httpLink = createHttpLink({
      uri: apiUrl,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: getAuthorizationHeader(),
        },
      };
    });
    this.client = new ApolloClient({
      cache: new InMemoryCache().restore({}),
      link: authLink.concat(httpLink),
    });

    // Make introspection of graphql api.
    this.isReady = Promise.resolve(true);

    Promise.all([true]);
  }

  private parseFilterOperator(key: string, value: any, separator = '|op=') {
    if (key.includes(separator)) {
      const [prop, op] = key.split(separator);
      return {
        [prop]: {
          [op]: value,
        },
      };
    }
    return {
      [key]: {
        equals: value,
      },
    };
  }

  private prepareAPIFilter(filter: any) {
    console.log('parseAPIFilter', filter);
    const { preparedFilter, ...fields } = filter;
    return preparedFilter
      ? fields
      : {
          AND: [
            ...Object.keys(fields).map((key) => ({
              ...this.parseFilterOperator(key, fields[key]),
            })),
          ],
        };
  }

  private filterDataParam(
    data: { [key: string]: any },
    operation: 'CREATE' | 'UPDATE',
  ): { [key: string]: any } {
    const retData: { [key: string]: any } = {};
    for (const key of Object.keys(data)) {
      if (!IGNORED_FIELDS.includes(key)) {
        if (typeof data[key] !== 'object') {
          retData[key] = operation === 'UPDATE' ? { set: data[key] } : data[key];
        } else if (Array.isArray(data[key])) {
          const verb = operation === 'UPDATE' ? 'set' : 'connect';
          retData[key] = {
            [verb]: data[key].map((v: any) =>
              Object.keys(v).includes('id') ? { id: v.id } : { name: v.name },
            ),
          };
        } else {
          const connectField = Object.keys(data[key]).includes('id') ? 'id' : 'name';
          retData[key] = {
            connect: { [connectField]: data[key][connectField] },
          };
        }
      }
    }
    return retData;
  }

  private paramsToVariables(raFetchType: ResourceOperation, params: any) {
    if (raFetchType.endsWith('_LIST')) {
      return {
        skip: (params.pagination.page - 1) * params.pagination.perPage,
        take: params.pagination.perPage,
        orderBy: [{ [params.sort.field]: params.sort.order.toLowerCase() }],
        where: this.prepareAPIFilter(params.filter),
      };
    } else if (raFetchType.endsWith('_MANY')) {
      return {
        where: {
          id: {
            in: params.ids,
          },
        },
      };
    } else if (['CREATE', 'UPDATE'].includes(raFetchType)) {
      const { id, ...data } = params.data;
      return {
        where: {
          id,
        },
        data: this.filterDataParam(data, raFetchType as 'CREATE' | 'UPDATE'),
      };
    } else if (raFetchType === 'DELETE') {
      return {
        id: params.id,
      };
    } else {
      return {
        where: { ...params },
      };
    }
  }

  public async buildGQLQuery(raFetchType: ResourceOperation, resourceName: string, params: any) {
    console.log(raFetchType, resourceName, params);

    const { queryName, queryText } = MATCHING_CONFIG[resourceName][raFetchType];
    const variables = this.paramsToVariables(raFetchType, params);
    console.log('Oauth2ServerAdapter query', queryText, variables);
    return {
      query: gql(queryText),
      variables,
      parseResponse: (response: any) => {
        console.log('API Response', queryName, response);
        let finalResponse: { data: any; total?: number } = { data: null };
        if (raFetchType === 'GET_LIST') {
          finalResponse = {
            data: response.data[queryName],
            total: response.data.total._count._all,
          };
        } else {
          finalResponse = {
            data: response.data[queryName],
          };
        }

        console.log('Final Response', finalResponse);
        return finalResponse;
      },
    };
  }

  public async buildGQLCustomQuery(queryName: string, params: any) {
    const query = CUSTOM_QUERIES_CONFIG[queryName];
    if (!query) {
      throw new Error(`Incorrect query name (${queryName}) passed to data provider.`);
    }

    return {
      query: gql`
        ${query.queryText}
      `,
      type: query.queryType,
      variables: {
        ...params,
      },
    };
  }

  public handleError(error: ApolloError): never {
    console.log('Oauth2ServerAdapter', error, error.graphQLErrors);

    // Trap netwwork error.
    if (error?.networkError as ServerError) {
      throw new HttpError(
        (error?.networkError as ServerError)?.message,
        (error?.networkError as ServerError)?.statusCode,
      );
    }

    // Trap validation error.
    const validationError = error.graphQLErrors.find((error) => error.name === 'ValidationError');
    if (validationError) {
      throw new ValidationError(validationError.message, validationError.extensions || {});
    }

    // Trap authentication error.
    const authenticationError = error.graphQLErrors.find(
      (error) => error.name === 'Unauthenticated',
    );
    if (authenticationError) {
      const authError = new Error(authenticationError.message);
      authError.name = 'AuthenticationError';
      throw authError;
    }

    // In this case populate ApolloError
    throw error;
  }

  public canHandleResource(resourceName: string) {
    if (Object.keys(MATCHING_CONFIG).includes(resourceName)) {
      return true;
    }
    return false;
  }

  public canHandleQuery(queryName: string) {
    if (Object.keys(CUSTOM_QUERIES_CONFIG).includes(queryName)) {
      return true;
    }
    return false;
  }
}
