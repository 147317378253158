import React, { useEffect } from 'react';

const ENV = process.env.NODE_ENV;

type ReactAdminLoginPageProps = {
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReactAdminLoginPage = ({ setLoggedIn }: ReactAdminLoginPageProps) => {
  useEffect(() => {
    /**
     * Next code is commented because on logout produces a session refresh before redirect logout finished.
     * Probably this component is not useful anymore but I'll keep the code until I am sure of that.
     */
    //setLoggedIn(false);
  }, []);
  return <div></div>;
};

export const withLoggedInStateChange = (
  Component: React.FC<ReactAdminLoginPageProps>,
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return (props: any) => <Component setLoggedIn={setLoggedIn} {...props} />;
};
