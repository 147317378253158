import React from 'react';
import { List, ListProps, Datagrid, TextField, EditButton, Filter, TextInput } from 'react-admin';

const RoleFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name|op=contains" label="resources.Role.fields.name" alwaysOn />
      <TextInput source="description|op=contains" label="resources.Role.fields.description" />
    </Filter>
  );
};

const RoleList = (props: ListProps) => {
  console.log('RoleList', props);

  return (
    <List {...props} filters={<RoleFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default RoleList;
