import React, { useEffect, useState } from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, AttachmentForm, validateAssociatedResource } from './Common';

const AttachmentCreate: React.FC = (props: any) => {
  const [redirectURL, setRedirectURL] = useState<string>();

  useEffect(() => {
    console.log('CREATE_ATTACHMENT', props.location.state);
    if (props.location?.state && props.location?.state?.redirectURL) {
      setRedirectURL(props.location.state.redirectURL);
    }
  }, []);

  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm
        mutationType="create"
        uploadFileAttribute="file"
        validate={validateAssociatedResource}
        redirect={() => redirectURL}
      >
        <AttachmentForm />
      </CustomForm>
    </Create>
  );
};

export default AttachmentCreate;
