import React, { MouseEvent } from 'react';
import {
  EditActionsProps,
  email,
  ListButton,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import VisibilityToolbarButton from '../../VisibilityToolbarButton';

const useStyles = makeStyles({
  address: {
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateFirstName = [required()];
const validateLegalIdentifier = [required()];
const validateEmail = [email()];
const validatePhone1 = [required()];
const validateLegalAddressAddress = [required()];
const validateLegalAddressPostalCode = [required()];
const validateLegalAddressCity = [required()];
const validateLegalAddressProvince = [required()];
const validateLegalAddressCountry = [required()];

export type CustomerActionsProps = EditActionsProps & {
  onShowAttachmentsChange?: (show: boolean) => void;
};
export const Actions = ({ basePath, onShowAttachmentsChange }: CustomerActionsProps) => {
  const translate = useTranslate();

  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeftIcon />} />
      {onShowAttachmentsChange && (
        <VisibilityToolbarButton
          label={translate('viatic.texts.attachments')}
          initialVisibility={false}
          onVisibilityChange={onShowAttachmentsChange}
        />
      )}
    </TopToolbar>
  );
};

export const CustomerForm: React.FC = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const statusList = useDynamicOptionList('L_STATUS');
  const identifierTypeList = useDynamicOptionList('L_IDENTIFIER_TYPE');
  const legalFormList = useDynamicOptionList('L_LEGAL_FORM');
  const languageList = useDynamicOptionList('L_LANGUAGE');
  const customerTypeList = useDynamicOptionList('L_CUSTOMER_TYPE');
  const { values } = useFormState();
  const { change } = useForm();

  const handleCopyLegalAddress = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    change('postalAddress.address', values.legalAddress.address);
    change('postalAddress.postalCode', values.legalAddress.postalCode);
    change('postalAddress.city', values.legalAddress.city);
    change('postalAddress.province', values.legalAddress.province);
    change('postalAddress.country', values.legalAddress.country);
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="firstName"
            resource="Customer"
            fullWidth
            validate={validateFirstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="lastName" resource="Customer" fullWidth />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="identifierType"
            resource="Customer"
            choices={identifierTypeList}
            defaultValue={identifierTypeList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInput
            source="legalIdentifier"
            resource="Customer"
            validate={validateLegalIdentifier}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="legalForm"
            resource="Customer"
            choices={legalFormList}
            defaultValue={legalFormList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput source="phone1" resource="Customer" validate={validatePhone1} fullWidth />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput source="phone2" resource="Customer" fullWidth />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <TextInput source="email" resource="Customer" fullWidth validate={validateEmail} />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="language"
            resource="Customer"
            choices={languageList}
            defaultValue={languageList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="status"
            resource="Customer"
            choices={statusList}
            defaultValue={statusList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="type"
            resource="Customer"
            choices={customerTypeList}
            defaultValue={customerTypeList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            label="viatic.labels.rate"
            source="rateId"
            reference="Rate"
            allowEmpty={true}
            filter={{
              status: 'A',
            }}
          >
            <SelectInput optionText="description" fullWidth />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={12} md={6} container direction="column">
          <Paper elevation={1} className={classes.address}>
            <Typography variant="h6">{translate('viatic.texts.legalAddress')}</Typography>
            <Grid item>
              <TextInput
                source="legalAddress.address"
                resource="Customer"
                fullWidth
                validate={validateLegalAddressAddress}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.postalCode"
                  resource="Customer"
                  fullWidth
                  validate={validateLegalAddressPostalCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.city"
                  resource="Customer"
                  fullWidth
                  validate={validateLegalAddressCity}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.province"
                  resource="Customer"
                  fullWidth
                  validate={validateLegalAddressProvince}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="legalAddress.country"
                  resource="Customer"
                  fullWidth
                  validate={validateLegalAddressCountry}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} container direction="column">
          <Paper elevation={1} className={classes.address}>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: '4px' }}
            >
              <Typography variant="h6">{translate('viatic.texts.postalAddress')}</Typography>
              <Button
                variant="text"
                color="primary"
                startIcon={<FileCopyIcon />}
                onClick={handleCopyLegalAddress}
                style={{ padding: 0 }}
              >
                {translate('viatic.texts.copyLegalAddress')}
              </Button>
            </Grid>
            <Grid item>
              <TextInput source="postalAddress.address" resource="Customer" fullWidth />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.postalCode" resource="Customer" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.city" resource="Customer" fullWidth />
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.province" resource="Customer" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput source="postalAddress.country" resource="Customer" fullWidth />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
