import React, { useState, useEffect, useContext } from 'react';
import { useDataProvider } from 'react-admin';
import GlobalLoading from '../components/GlobalLoading';

type SeriesList = {
  id: string;
  type: string;
  year: number;
  code: string;
  format: string;
  defaultValue: boolean;
}[];

type SeriesTable = {
  [key: string]: SeriesList;
};

export const SeriesContext = React.createContext<{
  seriesTable?: SeriesTable;
  updateSeriesTable?: () => void;
}>({});

export const SeriesProvider = (props: any) => {
  const [seriesTable, setSeriesTable] = useState<SeriesTable>();
  const dataProvider = useDataProvider();

  const updateSeriesTable = () => {
    dataProvider
      .getList('DocumentSeries', {
        filter: {},
        pagination: {
          page: 1,
          perPage: 10000,
        },
        sort: {
          field: 'year',
          order: 'asc',
        },
      })
      .then(({ data }) => {
        console.log('DocumentSeries result: ', data);
        const tmpSeriesTable: SeriesTable = {};
        for (const series of data) {
          if (!tmpSeriesTable[series.type]) {
            tmpSeriesTable[series.type] = [];
          }
          tmpSeriesTable[series.type].push({
            id: '' + series.id,
            type: series.type,
            year: series.year,
            code: series.code,
            format: series.format,
            defaultValue: series.defaultValue,
          });
        }
        console.log('Series table: ', tmpSeriesTable);
        setSeriesTable(tmpSeriesTable);
      });
  };

  useEffect(() => {
    updateSeriesTable();
  }, []);

  if (!seriesTable) {
    return <GlobalLoading size={150} message={'Cargando series...'} />;
  }

  return (
    <SeriesContext.Provider value={{ seriesTable, updateSeriesTable }}>
      {props.children}
    </SeriesContext.Provider>
  );
};

export const useSeriesList = (type: string): SeriesList => {
  const { seriesTable } = useContext(SeriesContext);

  return seriesTable ? seriesTable[type] || [] : [];
};

export const useUpdateSeriesTable = () => {
  const { updateSeriesTable } = useContext(SeriesContext);

  return updateSeriesTable;
};
