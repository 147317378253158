import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  BooleanField,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const ConceptFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <BooleanInput source="requiresStartDate" fullWidth />
      <BooleanInput source="requiresEndDate" />
      <BooleanInput source="requiresOriginBurialUnit" />
      <BooleanInput source="requiresDestinationBurialUnit" />
      <BooleanInput source="requiresAddress" />
      <BooleanInput source="requiresOven" />
      <BooleanInput source="requiresOriginCemetery" />
      <BooleanInput source="requiresDestinationCemetery" />
      <TextInput source="description|op=LIKE" label="resources.Concept.fields.description" />
    </Filter>
  );
};

const ConceptList = (props: ListProps) => {
  const conceptTypeList = useDynamicOptionList('L_CONCEPT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');
  console.log(statusList);

  return (
    <List {...props} filters={<ConceptFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <SelectField source="type" choices={conceptTypeList} />
        <TextField source="description" />
        <BooleanField source="requiresStartDate" />
        <BooleanField source="requiresEndDate" />
        <BooleanField source="requiresOriginBurialUnit" />
        <BooleanField source="requiresDestinationBurialUnit" />
        <BooleanField source="requiresAddress" />
        <BooleanField source="requiresOven" />
        <BooleanField source="requiresOriginCemetery" />
        <BooleanField source="requiresDestinationCemetery" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ConceptList;
