import React, { useState, useEffect, useContext, ReactElement } from 'react';
import { useDataProvider } from 'react-admin';
import GlobalLoading from '../components/GlobalLoading';

type Period = {
  id: string;
  description: string;
  startDate: Date;
  endDate: Date;
  current: boolean;
};

export const CurrentPeriodContext = React.createContext<{
  currentPeriod?: Period;
}>({});

export const CurrentPeriodProvider = (props: { children: ReactElement }) => {
  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const dataProvider = useDataProvider();

  const updateCurrentPeriod = () => {
    dataProvider
      .getList('Period', {
        filter: {
          current: 'Y',
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'description',
          order: 'desc',
        },
      })
      .then(({ data }) => {
        console.log('Current period result: ', data);
        if (data && data.length) {
          const { id, description, startDate, endDate } = data[0];
          setCurrentPeriod({
            id: id as string,
            description: description as string,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            current: true,
          });
        }
      });
  };

  useEffect(() => {
    updateCurrentPeriod();
  }, [dataProvider]);

  if (!currentPeriod) {
    return <GlobalLoading size={150} message={'Periodo actual...'} />;
  }

  return (
    <CurrentPeriodContext.Provider value={{ currentPeriod }}>
      {props.children}
    </CurrentPeriodContext.Provider>
  );
};

export const useCurrentPeriod = (): Period | undefined => {
  const { currentPeriod } = useContext(CurrentPeriodContext);

  return currentPeriod;
};
