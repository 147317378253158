import React from 'react';
import { CircularProgress, Box, Typography } from '@material-ui/core'


const GlobalLoading = ({ size, message }: { size: number; message: string }) => (
  <div style={{
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <Box position="relative" display="inline-flex">
      <CircularProgress thickness={1} size={size} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1" component="div" color="primary">{message}</Typography>
      </Box>
    </Box>
  </div>
)

export default GlobalLoading;