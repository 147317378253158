import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  EditActionsProps,
  ListButton,
  PasswordInput,
  Record,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useDataProvider,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';

export const LOCALES = [
  { id: 'es_ES', name: 'Español, España' },
  { id: 'en_US', name: 'Ingles, Americano' },
];

const DEFAULT_LOCALE = 'es_ES';

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const UserForm = ({ mutationType }: MutationTypeProps) => {
  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextInput source="email" resource="User" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="firstName" resource="User" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="lastName" resource="User" validate={[required()]} fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput source="locale" choices={LOCALES} defaultValue={DEFAULT_LOCALE} fullWidth />
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={12}
          md={2}
        >
          <BooleanInput source="locked" resource="User" defaultValue={false} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="viatic.labels.rol"
            source="role.id"
            reference="Role"
            validate={[required()]}
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <PasswordInput
            source="password"
            resource="User"
            validate={mutationType === 'create' ? [required()] : []}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
