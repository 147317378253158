import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export default merge({}, defaultTheme, {
  palette: {
    secondary: {
      main: '#5DB2E4',
    },
    primary: {
      main: '#E1B77D',
    },
  },
});
