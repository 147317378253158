import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EmailField,
  SelectField,
  EditButton,
  FilterProps,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const statusList = [
  { id: 'AVAILABLE', name: 'Libre', default: true },
  { id: 'USED', name: 'Ocupada', default: false },
  { id: 'BLOCK', name: 'Bloqueada', default: false },
  { id: 'RESERVED', name: 'Reservada', default: false },
];

const BurialUnitFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const cemeteryList = useDynamicOptionList('L_CEMETERY');
  const situationList = useDynamicOptionList('L_BURIAL_UNIT_SITUATION');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <SelectInput source="cemetery" choices={cemeteryList} />
      <SelectInput source="situation" choices={situationList} />
      <TextInput source="zone|op=LIKE" label="resources.BurialUnit.fields.zone" />
      <TextInput source="yard|op=LIKE" label="resources.BurialUnit.fields.yard" />
      <TextInput source="row|op=LIKE" label="resources.BurialUnit.fields.row" />
      <TextInput source="number|op=LIKE" label="resources.BurialUnit.fields.number" />
    </Filter>
  );
};

const BurialUnitList = (props: ListProps) => {
  const burialUnitTypeList = useDynamicOptionList('L_BURIAL_UNIT_TYPE');
  const cemeteryList = useDynamicOptionList('L_CEMETERY');
  const statusList = useDynamicOptionList('L_STATUS');
  const situationList = useDynamicOptionList('L_BURIAL_UNIT_SITUATION');

  return (
    <List {...props} filters={<BurialUnitFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <SelectField source="type" choices={burialUnitTypeList} />
        <SelectField source="cemetery" choices={cemeteryList} />
        <SelectField source="situation" choices={situationList} />
        <TextField source="zone" />
        <TextField source="yard" />
        <TextField source="row" />
        <TextField source="number" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default BurialUnitList;
