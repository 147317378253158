import _ from 'lodash';
import {
  ApolloClient,
  ApolloError,
  createHttpLink,
  gql,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { IntrospectionResult, introspectSchema } from '../helpers/introspection';
import {
  ResourceOperation,
  AbstractAdapter,
  CustomQueryMatchingConfiguration,
} from './AbstractAdapter';
import dayjs from 'dayjs';
import { HttpError } from 'react-admin';

import ValidationError from '../errors/ValidationError';

type ResourceConfiguration = {
  operationQueries: {
    [operation in ResourceOperation]?: { queryName: string; depth: number };
  };
  booleanFields: string[];
};
type ResourceMatchingConfiguration = {
  [resource: string]: ResourceConfiguration;
};

// Common data provider config and functions.
const MATCHING_CONFIG: ResourceMatchingConfiguration = {
  Attachment: {
    operationQueries: {
      GET_LIST: { queryName: 'getAttachmentList', depth: 1 },
      GET_ONE: { queryName: 'getAttachmentById', depth: 1 },
      GET_MANY: { queryName: 'getAttachmentManyByIds', depth: 0 },
      CREATE: { queryName: 'attachmentCreate', depth: 0 },
      UPDATE: { queryName: 'attachmentUpdate', depth: 0 },
      DELETE: { queryName: 'attachmentDelete', depth: 0 },
    },
    booleanFields: ['defaultValue'],
  },
  Budget: {
    operationQueries: {
      GET_LIST: { queryName: 'getBudgetList', depth: 0 },
      GET_ONE: { queryName: 'getBudgetById', depth: 1 },
      GET_MANY: { queryName: 'getBudgetManyByIds', depth: 0 },
      CREATE: { queryName: 'budgetCreate', depth: 0 },
      UPDATE: { queryName: 'budgetUpdate', depth: 0 },
      DELETE: { queryName: 'budgetDelete', depth: 0 },
    },
    booleanFields: [],
  },
  BudgetLine: {
    operationQueries: {
      GET_LIST: { queryName: 'getBudgetLineList', depth: 0 },
      GET_ONE: { queryName: 'getBudgetLineById', depth: 0 },
      GET_MANY: { queryName: 'getBudgetLineManyByIds', depth: 0 },
      CREATE: { queryName: 'budgetLineCreate', depth: 0 },
      UPDATE: { queryName: 'budgetLineUpdate', depth: 0 },
      DELETE: { queryName: 'budgetLineDelete', depth: 0 },
    },
    booleanFields: [],
  },
  BurialUnit: {
    operationQueries: {
      GET_LIST: { queryName: 'getBurialUnitList', depth: 0 },
      GET_ONE: { queryName: 'getBurialUnitById', depth: 0 },
      GET_MANY: { queryName: 'getBurialUnitManyByIds', depth: 0 },
      CREATE: { queryName: 'burialUnitCreate', depth: 0 },
      UPDATE: { queryName: 'burialUnitUpdate', depth: 0 },
      DELETE: { queryName: 'burialUnitDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Company: {
    operationQueries: {
      GET_LIST: { queryName: 'getCompanyList', depth: 0 },
      GET_ONE: { queryName: 'getCompanyById', depth: 1 },
      GET_MANY: { queryName: 'getCompanyManyByIds', depth: 1 },
      CREATE: { queryName: 'companyCreate', depth: 1 },
      UPDATE: { queryName: 'companyUpdate', depth: 1 },
      DELETE: { queryName: 'companyDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Concept: {
    operationQueries: {
      GET_LIST: { queryName: 'getConceptList', depth: 0 },
      GET_ONE: { queryName: 'getConceptById', depth: 1 },
      GET_MANY: { queryName: 'getConceptManyByIds', depth: 1 },
      CREATE: { queryName: 'conceptCreate', depth: 1 },
      UPDATE: { queryName: 'conceptUpdate', depth: 1 },
      DELETE: { queryName: 'conceptDelete', depth: 0 },
    },
    booleanFields: [
      'requiresStartDate',
      'requiresEndDate',
      'requiresOriginBurialUnit',
      'requiresDestinationBurialUnit',
      'requiresAddress',
      'requiresOven',
      'requiresOriginCemetery',
      'requiresDestinationCemetery',
    ],
  },
  ConceptRate: {
    operationQueries: {
      GET_LIST: { queryName: 'getConceptRateList', depth: 1 },
      GET_ONE: { queryName: 'getConceptRateById', depth: 1 },
      GET_MANY: { queryName: 'getConceptRateManyByIds', depth: 1 },
      CREATE: { queryName: 'conceptRateCreate', depth: 1 },
      UPDATE: { queryName: 'conceptRateUpdate', depth: 1 },
      DELETE: { queryName: 'conceptRateDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Customer: {
    operationQueries: {
      GET_LIST: { queryName: 'getCustomerList', depth: 0 },
      GET_ONE: { queryName: 'getCustomerById', depth: 1 },
      GET_MANY: { queryName: 'getCustomerManyByIds', depth: 1 },
      CREATE: { queryName: 'customerCreate', depth: 1 },
      UPDATE: { queryName: 'customerUpdate', depth: 1 },
      DELETE: { queryName: 'customerDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Deceased: {
    operationQueries: {
      GET_LIST: { queryName: 'getDeceasedList', depth: 0 },
      GET_ONE: { queryName: 'getDeceasedById', depth: 0 },
      GET_MANY: { queryName: 'getDeceasedManyByIds', depth: 0 },
      CREATE: { queryName: 'deceasedCreate', depth: 0 },
      UPDATE: { queryName: 'deceasedUpdate', depth: 0 },
      DELETE: { queryName: 'deceasedDelete', depth: 0 },
    },
    booleanFields: [],
  },
  DocumentSeries: {
    operationQueries: {
      GET_LIST: { queryName: 'getDocumentSeriesList', depth: 0 },
      GET_ONE: { queryName: 'getDocumentSeriesById', depth: 0 },
      GET_MANY: { queryName: 'getDocumentSeriesManyByIds', depth: 0 },
      CREATE: { queryName: 'documentSeriesCreate', depth: 0 },
      UPDATE: { queryName: 'documentSeriesUpdate', depth: 0 },
      DELETE: { queryName: 'documentSeriesDelete', depth: 0 },
    },
    booleanFields: ['defaultValue'],
  },
  DynamicOption: {
    operationQueries: {
      GET_LIST: { queryName: 'getDynamicOptionList', depth: 0 },
      GET_MANY: { queryName: 'getDynamicOptionManyByIds', depth: 1 },
    },
    booleanFields: [],
  },
  Invoice: {
    operationQueries: {
      GET_LIST: { queryName: 'getInvoiceList', depth: 0 },
      GET_ONE: { queryName: 'getInvoiceById', depth: 1 },
      GET_MANY: { queryName: 'getInvoiceManyByIds', depth: 0 },
      CREATE: { queryName: 'invoiceCreate', depth: 0 },
      UPDATE: { queryName: 'invoiceUpdate', depth: 0 },
      DELETE: { queryName: 'invoiceDelete', depth: 0 },
    },
    booleanFields: [],
  },
  InvoiceLine: {
    operationQueries: {
      GET_LIST: { queryName: 'getInvoiceLineList', depth: 0 },
      GET_ONE: { queryName: 'getInvoiceLineById', depth: 0 },
      GET_MANY: { queryName: 'getInvoiceLineManyByIds', depth: 0 },
      CREATE: { queryName: 'invoiceLineCreate', depth: 0 },
      UPDATE: { queryName: 'invoiceLineUpdate', depth: 0 },
      DELETE: { queryName: 'invoiceLineDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Oven: {
    operationQueries: {
      GET_LIST: { queryName: 'getOvenList', depth: 0 },
      GET_ONE: { queryName: 'getOvenById', depth: 1 },
      GET_MANY: { queryName: 'getOvenManyByIds', depth: 0 },
      CREATE: { queryName: 'ovenCreate', depth: 1 },
      UPDATE: { queryName: 'ovenUpdate', depth: 1 },
      DELETE: { queryName: 'ovenDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Period: {
    operationQueries: {
      GET_LIST: { queryName: 'getPeriodList', depth: 0 },
      GET_ONE: { queryName: 'getPeriodById', depth: 1 },
      GET_MANY: { queryName: 'getPeriodManyByIds', depth: 0 },
      CREATE: { queryName: 'periodCreate', depth: 1 },
      UPDATE: { queryName: 'periodUpdate', depth: 1 },
      DELETE: { queryName: 'periodDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Rate: {
    operationQueries: {
      GET_LIST: { queryName: 'getRateList', depth: 0 },
      GET_ONE: { queryName: 'getRateById', depth: 1 },
      GET_MANY: { queryName: 'getRateManyByIds', depth: 0 },
      CREATE: { queryName: 'rateCreate', depth: 1 },
      UPDATE: { queryName: 'rateUpdate', depth: 1 },
      DELETE: { queryName: 'rateDelete', depth: 0 },
    },
    booleanFields: [],
  },
  Trigger: {
    operationQueries: {
      GET_LIST: { queryName: 'getTriggerList', depth: 1 },
      GET_ONE: { queryName: 'getTriggerById', depth: 1 },
      GET_MANY: { queryName: 'getTriggerManyByIds', depth: 1 },
      CREATE: { queryName: 'triggerCreate', depth: 1 },
      UPDATE: { queryName: 'triggerUpdate', depth: 1 },
      DELETE: { queryName: 'triggerDelete', depth: 0 },
    },
    booleanFields: [],
  },
  WorkOrder: {
    operationQueries: {
      GET_LIST: { queryName: 'getWorkOrderList', depth: 0 },
      GET_ONE: { queryName: 'getWorkOrderById', depth: 1 },
      GET_MANY: { queryName: 'getWorkOrderManyByIds', depth: 0 },
      CREATE: { queryName: 'workOrderCreate', depth: 0 },
      UPDATE: { queryName: 'workOrderUpdate', depth: 0 },
      DELETE: { queryName: 'workOrderDelete', depth: 0 },
    },
    booleanFields: [],
  },
  WorkOrderLine: {
    operationQueries: {
      GET_LIST: { queryName: 'getWorkOrderLineList', depth: 0 },
      GET_ONE: { queryName: 'getWorkOrderLineById', depth: 0 },
      GET_MANY: { queryName: 'getWorkOrderLineManyByIds', depth: 0 },
      CREATE: { queryName: 'workOrderLineCreate', depth: 0 },
      UPDATE: { queryName: 'workOrderLineUpdate', depth: 0 },
      DELETE: { queryName: 'workOrderLineDelete', depth: 0 },
    },
    booleanFields: [],
  },
};

const CUSTOM_QUERIES_CONFIG: CustomQueryMatchingConfiguration = {
  budgetAccept: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: IdInput!, $companyId: ID!) {
          budgetAccept(input: $input, companyId: $companyId) {
              id situation
          }
      }
    `,
  },
  budgetToInvoice: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: ContableConvertInput!, $companyId: ID!) {
          budgetToInvoice(input: $input, companyId: $companyId) {
              id
          }
      }  
    `,
  },
  budgetToWorkOrder: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: ContableConvertInput!, $companyId: ID!) {
          budgetToWorkOrder(input: $input, companyId: $companyId) {
              id
          }
      }  
    `,
  },
  workOrderFinalize: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: IdInput!, $companyId: ID!) {
          workOrderFinalize(input: $input, companyId: $companyId) {
              id situation
          }
      }
    `,
  },
  workOrderAssignWorker: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: WorkOrderAssignWorkerInput!, $companyId: ID!) {
        workOrderAssignWorker(input: $input, companyId: $companyId) {
          id workerUserId workerName
        }
      }
    `,
  },
  workOrderToInvoice: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: ContableListConvertInput!, $companyId: ID!) {
          workOrderToInvoice(input: $input, companyId: $companyId) {
              id
          }
      }  
    `,
  },
  invoiceCancel: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: ContableConvertInput!, $companyId: ID!) {
          invoiceCancel(input: $input, companyId: $companyId) {
              id
          }
      }  
    `,
  },
  invoicePaid: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: InvoicePaymentInput, $companyId: ID!) {
        invoicePaid(input: $input, companyId: $companyId) {
          id
        }
      }  
    `,
  },
  invoiceRefund: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: InvoicePaymentInput, $companyId: ID!) {
        invoiceRefund(input: $input, companyId: $companyId) {
          id
        }
      }  
    `,
  },
  invoiceRectified: {
    queryType: 'mutation',
    queryText: `
      mutation ($input: ContableConvertInput!, $companyId: ID!) {
          invoiceRectified(input: $input, companyId: $companyId) {
              id
          }
      }  
    `,
  },
};

const IGNORED_FIELDS = [
  'createdAt',
  'createdBy',
  'deletedAt',
  'deletedBy',
  'updatedAt',
  'updatedBy',
  '__typename',
  'file',
];

export class ViaticAdapter implements AbstractAdapter {
  private introspectionResults?: IntrospectionResult;
  private gqlQueryCache: { [resource: string]: { [method: string]: string } } = {
    // Rate: {
    //   GET_ONE: `
    //     query ($id: ID!, $companyId: ID!) {
    //       getRateById(id: $id, companyId: $companyId) {
    //           id description i18nDescriptions {  es en } parentRateId status
    //       }
    //     }
    //   `
    // }
    Budget: {
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getBudgetById(id: $id, companyId: $companyId) {
              id legalNameIssuer identifierTypeIssuer legalIdentifierIssuer address1Issuer address2Issuer phoneIssuer emailIssuer customerId legalNameCustomer 
              identifierTypeCustomer legalIdentifierCustomer address1Customer address2Customer phoneCustomer emailCustomer documentSerieId 
              number format date subtotal total currency rate {  id description parentRateId status } rateId deceasedId 
              budgetLineList {  list { id conceptId conceptRateId description } total } document {  id type path status } documentId 
              workOrderId invoiceId paymentMethodKey paymentMethodValue paymentInstrumentKey paymentInstrumentValue situation status
          }
        }
      `,
    },
    BudgetLine: {
      GET_LIST: `
        query ($pagination: Pagination, $sort: Sort, $filter: Filter, $companyId: ID!) {
          getBudgetLineList(pagination: $pagination, sort: $sort, filter: $filter, companyId: $companyId) {
            list {
              id budgetId conceptId conceptRateId number description units amountUnit taxType amountSubtotal amountTax amountTotal currency startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            } total
          }
        }
      `,
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getBudgetLineById(id: $id, companyId: $companyId) {
            id budgetId conceptId conceptRateId number description units amountUnit taxType amountSubtotal amountTax amountTotal currency startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            address { id address postalCode city province country }
            budget {
              format
            } 
          }
        }
      `,
    },
    Invoice: {
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getInvoiceById(id: $id, companyId: $companyId) {
              id legalNameIssuer identifierTypeIssuer legalIdentifierIssuer address1Issuer address2Issuer phoneIssuer emailIssuer customerId legalNameCustomer 
              identifierTypeCustomer legalIdentifierCustomer address1Customer address2Customer phoneCustomer emailCustomer documentSerieId 
              number format date subtotal total currency rate {  id description parentRateId status } rateId deceasedId 
              invoiceLineList {  list { id conceptId conceptRateId description } total } document {  id type path status } documentId 
              paymentMethodKey paymentMethodValue paymentInstrumentKey paymentInstrumentValue situation status
          }
        }
      `,
    },
    InvoiceLine: {
      GET_LIST: `
        query ($pagination: Pagination, $sort: Sort, $filter: Filter, $companyId: ID!) {
          getInvoiceLineList(pagination: $pagination, sort: $sort, filter: $filter, companyId: $companyId) {
            list {
              id invoiceId conceptId conceptRateId number description units amountUnit taxType amountSubtotal amountTax amountTotal currency startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            } total
          }
        }
      `,
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getInvoiceLineById(id: $id, companyId: $companyId) {
            id invoiceId conceptId conceptRateId number description units amountUnit taxType amountSubtotal amountTax amountTotal currency startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            address { id address postalCode city province country }
            invoice {
              format
            } 
          }
        }
      `,
    },
    WorkOrder: {
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getWorkOrderById(id: $id, companyId: $companyId) {
              id legalNameIssuer identifierTypeIssuer legalIdentifierIssuer address1Issuer address2Issuer phoneIssuer emailIssuer 
              customerId legalNameCustomer identifierTypeCustomer legalIdentifierCustomer address1Customer address2Customer phoneCustomer emailCustomer 
              documentSerieId number format date deceasedId workOrderLineList {  list { id conceptId } total } document {  id type path status } documentId 
              workerUserId workerName  budgetId situation status finalizedAt finalizedBy
          }
        }
      `,
    },
    WorkOrderLine: {
      GET_LIST: `
        query ($pagination: Pagination, $sort: Sort, $filter: Filter, $companyId: ID!) {
          getWorkOrderLineList(pagination: $pagination, sort: $sort, filter: $filter, companyId: $companyId) {
            list {
              id workOrderId conceptId number description units startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            } total
          }
        }
      `,
      GET_ONE: `
        query ($id: ID!, $companyId: ID!) {
          getWorkOrderLineById(id: $id, companyId: $companyId) {
            id workOrderId conceptId number description units startDate endDate ovenId originBurialUnitId destinationBurialUnitId originCemetery destinationCemetery status
            address { id address postalCode city province country }
            workOrder {
              format
            } 
          }
        }
      `,
    },
  };
  public client: ApolloClient<unknown>;
  public isReady: Promise<any>;

  constructor(
    apiUrl: string,
    getAuthorizationHeader: () => string | undefined,
    private companyId: string,
  ) {
    // Create Apollo client.
    const httpLink = createHttpLink({
      uri: apiUrl,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: getAuthorizationHeader(),
        },
      };
    });
    this.client = new ApolloClient({
      cache: new InMemoryCache().restore({}),
      link: authLink.concat(httpLink),
    });

    // Make introspection of graphql api.
    this.isReady = new Promise((resolve, reject) => {
      introspectSchema(this.client, {
        operationNames: {},
        exclude: undefined,
        include: undefined,
      })
        .then((result) => {
          console.log('GraphqlDataProvider: Introspection results', result);
          this.introspectionResults = result;
          resolve(undefined);
        })
        .catch((error) => {
          console.log('Error introspecting API', error);
          reject(error);
        });
    });
  }

  private describeGraphQLType(objType: any): string {
    if (objType.name) return objType.name;
    if (objType.kind === 'NON_NULL') return `${this.describeGraphQLType(objType.ofType)}!`;
    if (objType.kind === 'LIST') return `[${this.describeGraphQLType(objType.ofType)}]`;
    throw new Error('Unexpected param kind type detected ' + objType.kind);
  }

  private listFields(objType: any, depth: number = 1): string {
    let fieldList = '';

    for (const field of objType.fields) {
      if (field.type.kind === 'SCALAR' && !IGNORED_FIELDS.includes(field.name)) {
        fieldList = `${fieldList} ${field.name}`;
      } else if (field.type.kind === 'OBJECT' && depth) {
        const fieldType = this.introspectionResults?.types.find(
          (t: any) => t.name === field.type.name,
        );
        fieldList = `${fieldList} ${field.name} { ${this.listFields(fieldType, depth - 1)} }`;
      } else if (field.type.kind === 'LIST' && depth) {
        const fieldType = this.introspectionResults?.types.find(
          (t: any) => t.name === field.type.ofType.name,
        );
        fieldList = `${fieldList} ${field.name} { ${this.listFields(fieldType, depth - 1)} }`;
      }
    }

    return fieldList;
  }

  private formatResponse(data: any, booleanFields: string[]): any {
    const formattedData = {};
    let fields = Object.keys(data);
    while (fields.length > 0) {
      const key = fields.shift();
      if (!key) continue;
      const value = _.get(data, key);
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        fields = fields.concat(Object.keys(value).map((subkey) => `${key}.${subkey}`));
      } else {
        let formattedValue = value;
        if (booleanFields.includes(key)) {
          if (Array.isArray(value)) {
            formattedValue = value.map((v) => v === 'Y');
          } else {
            formattedValue = value === 'Y';
          }
        }
        _.set(formattedData, key, formattedValue);
      }
    }
    return formattedData;
  }

  private parseDataParam(paramName: string, paramValue: any, booleanFields: string[]): any {
    if (booleanFields.includes(paramName)) {
      return paramValue ? 'Y' : 'N';
    }
    if (paramValue instanceof Date && !isNaN(paramValue.getDate())) {
      let dateISO;
      try {
        dateISO = dayjs(paramValue).format('YYYY-MM-DDTHH:mm:ssZZ');
      } catch (error) {
        dateISO = null;
      }
      return dateISO;
    }
    return paramValue;
  }

  private filterDataParam(
    data: { [key: string]: any },
    resourceBooleanFields: string[],
  ): { [key: string]: any } {
    const retData: { [key: string]: any } = {};
    for (const key of Object.keys(data)) {
      let newKey;
      if (key === '##### IMPOSIBLE VALUE') {
        newKey = 'descriptions';
      } else {
        newKey = key;
      }
      if (!IGNORED_FIELDS.includes(key)) {
        retData[newKey] =
          data[key] &&
          typeof data[key] === 'object' &&
          !Array.isArray(data[key]) &&
          !(data[key] instanceof Date)
            ? this.filterDataParam(data[key], resourceBooleanFields)
            : this.parseDataParam(key, data[key], resourceBooleanFields);
      }
    }

    return retData;
  }

  private parseFilterOperator(key: string, separator = '|op=') {
    if (key.includes(separator)) {
      const [prop, op] = key.split(separator);
      return {
        attribute: prop,
        comparator: op,
      };
    }
    return {
      attribute: key,
      comparator: '=',
    };
  }

  private prepareAPIFilter(filter: any, booleanFields: string[]) {
    console.log('parseAPIFilter', filter);
    const { preparedFilter, search, status, ...fields } = filter;
    return preparedFilter
      ? fields
      : {
          ...(search && { search }),
          ...(status && { status }),
          conditions: [
            ...Object.keys(fields).map((key) => ({
              ...this.parseFilterOperator(key),
              values: [!booleanFields.includes(key) ? fields[key] : fields[key] ? 'Y' : 'N'],
            })),
          ],
        };
  }

  public async buildGQLQuery(raFetchType: ResourceOperation, resourceName: string, params: any) {
    console.log(raFetchType, resourceName, params);
    console.log('COMPANY ID', this.companyId);

    // Especial resource OPTIONS.
    if (resourceName === 'OPTIONS') {
    }

    // Normal graphql resources
    const paramsToVariables = () => {
      if (raFetchType.endsWith('_LIST')) {
        return {
          pagination: {
            page: params.pagination.page,
            perPage: params.pagination.perPage,
          },
          sort: {
            orderBy: params.sort.field,
            orderDirection: params.sort.order,
          },
          filter: this.prepareAPIFilter(params.filter, MATCHING_CONFIG[resourceName].booleanFields),
          companyId: this.companyId,
        };
      } else if (['CREATE', 'UPDATE'].includes(raFetchType)) {
        return {
          input: this.filterDataParam(params.data, MATCHING_CONFIG[resourceName].booleanFields),
          companyId: this.companyId,
        };
      } else if (raFetchType === 'DELETE') {
        return {
          input: {
            id: params.id,
          },
          companyId: this.companyId,
        };
      } else {
        return {
          ...params,
          companyId: this.companyId,
        };
      }
    };

    const queryName = MATCHING_CONFIG[resourceName].operationQueries[raFetchType]?.queryName;
    if (!queryName) {
      throw new Error(
        `Incorrect resource name (${resourceName}) or fetch operation (${raFetchType}) passed to data provider.`,
      );
    }

    let gqlQuery;

    if (this.gqlQueryCache[resourceName] && this.gqlQueryCache[resourceName][raFetchType]) {
      console.log('Query found in cache!!!!!');
      gqlQuery = this.gqlQueryCache[resourceName][raFetchType];
    } else {
      const queryType = raFetchType.startsWith('GET') ? 'query' : 'mutation';

      const queryObject = this.introspectionResults?.queries.find((q: any) => q.name === queryName);
      if (!queryObject) {
        throw new Error(`Cannot find query ${queryName} in GraphQL schema`);
      }

      const queryParams = queryObject.args.map(
        (arg: any) => `$${arg.name}: ${this.describeGraphQLType(arg.type)}`,
      );
      const queryArgs = queryObject.args.map((arg: any) => `${arg.name}: $${arg.name}`);
      const queryReturnType = this.introspectionResults?.types.find(
        (type: any) => type.name === resourceName,
      );
      if (!queryReturnType) {
        throw new Error(
          `Cannot find a return type for query ${queryName} of resource ${resourceName}`,
        );
      }
      const fieldList = this.listFields(
        queryReturnType,
        MATCHING_CONFIG[resourceName].operationQueries[raFetchType]?.depth,
      );

      gqlQuery = `
        ${queryType} (${queryParams.join(', ')}) {
          ${queryName}(${queryArgs.join(', ')}) {
            ${raFetchType === 'GET_LIST' ? 'list {' : ''}
              ${fieldList}
            ${raFetchType === 'GET_LIST' ? '} total' : ''}
          }
        }
      `;

      this.gqlQueryCache[resourceName] = {
        ...this.gqlQueryCache[resourceName],
        [raFetchType]: gqlQuery,
      };
    }

    console.log(gqlQuery, paramsToVariables());

    return {
      query: gql`
        ${gqlQuery}
      `,
      variables: paramsToVariables(),
      parseResponse: (response: any) => {
        console.log('API Response', queryName, response);
        let finalResponse;
        if (raFetchType === 'GET_LIST') {
          finalResponse = {
            data: response.data[queryName].list.map((data: any) =>
              this.formatResponse(data, MATCHING_CONFIG[resourceName].booleanFields),
            ),
            total: response.data[queryName].total,
          };
        } else if (raFetchType === 'GET_MANY') {
          finalResponse = {
            data: response.data[queryName].map((data: any) =>
              this.formatResponse(data, MATCHING_CONFIG[resourceName].booleanFields),
            ),
          };
        } else {
          finalResponse = {
            data: this.formatResponse(
              response.data[queryName],
              MATCHING_CONFIG[resourceName].booleanFields,
            ),
          };
        }

        console.log('Final Response', finalResponse);
        return finalResponse;
      },
    };
  }

  public async buildGQLCustomQuery(queryName: string, params: any) {
    const query = CUSTOM_QUERIES_CONFIG[queryName];
    if (!query) {
      throw new Error(`Incorrect query name (${queryName}) passed to data provider.`);
    }

    return {
      query: gql`
        ${query.queryText}
      `,
      type: query.queryType,
      variables: {
        ...params,
        companyId: this.companyId,
      },
    };
  }

  public handleError(error: ApolloError): never {
    console.log('GraphQLDataProvider', error, error.graphQLErrors);

    // Trap netwwork error.
    if (error?.networkError as ServerError) {
      throw new HttpError(
        (error?.networkError as ServerError)?.message,
        (error?.networkError as ServerError)?.statusCode,
      );
    }

    // Trap validation error.
    const validationError = error.graphQLErrors.find((error) => error.name === 'ValidationError');
    if (validationError) {
      throw new ValidationError(validationError.message, validationError.extensions || {});
    }

    // Trap authentication error.
    const authenticationError = error.graphQLErrors.find(
      (error) => error.name === 'Unauthenticated',
    );
    if (authenticationError) {
      // BE AWARE: If user has not sufficent privileges application enters in a infinite loop.
      const authError = new Error(authenticationError.message);
      authError.name = 'AuthenticationError';
      throw authError;
    }

    // In this case populate ApolloError
    throw error;
  }

  public canHandleResource(resourceName: string) {
    if (Object.keys(MATCHING_CONFIG).includes(resourceName)) {
      return true;
    }
    return false;
  }

  public canHandleQuery(queryName: string) {
    if (Object.keys(CUSTOM_QUERIES_CONFIG).includes(queryName)) {
      return true;
    }
    return false;
  }

  public getCompanyId() {
    return this.companyId;
  }

  public setCompanyId(companyId: string) {
    this.companyId = companyId;
  }
}
