import React, { useState } from 'react';
import {
  ArrayInput,
  EditActionsProps,
  email,
  FormDataConsumer,
  ListButton,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import CustomForm, { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const useStyles = makeStyles({
  address: {
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateLegalName = [required()];
const validateIdentifierType = [required()];
const validateLegalIdentifier = [required()];
const validateEmail = [email()];
const validateLegalAddressAddress = [required()];
const validateLegalAddressPostalCode = [required()];
const validateLegalAddressCity = [required()];
const validateLegalAddressProvince = [required()];
const validateLegalAddressCountry = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const OvenForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const cemeteryList = useDynamicOptionList('L_CEMETERY');
  const statusList = useDynamicOptionList('L_STATUS');
  const languageList = useDynamicOptionList('L_LANGUAGE');

  const [localeList] = useState(languageList.map((lang) => lang.id));

  return (
    <>
      {mutationType === 'update' && (
        <Grid item>
          <TextInput source="description" resource="Oven" fullWidth disabled />
        </Grid>
      )}
      <Grid item container spacing={1}>
        <Grid item xs={12} md={4}>
          <SelectInput source="cemetery" resource="Oven" choices={cemeteryList} fullWidth />
        </Grid>
        {mutationType === 'update' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="Oven"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography variant="h6">{translate('viatic.texts.translations')}</Typography>
        <TranslatableInputs locales={localeList}>
          <TextInput source="i18nDescriptions" fullWidth />
        </TranslatableInputs>
      </Grid>
    </>
  );
};
