import React, { useContext, useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DataProviderContext,
  DateInput,
  EditActionsProps,
  EditButton,
  FormDataConsumer,
  Identifier,
  ListButton,
  Record,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useAuthProvider,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useFormState } from 'react-final-form';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import { config } from '../../../helpers/config';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { Link } from 'react-router-dom';
import DeleteListItem from '../../DeleteListItem';
import EditWorkerDialog from './EditWorkerDialog';
import { GraphQLDataProvider } from '../../../providers/GraphQLDataProvider';
import { downloadFile } from '../../../helpers/transfer-file';
import GenerateInvoiceDialog from './GenerateInvoiceDialog';
import { useSeriesList } from '../../../contexts/Series';

const useStyles = makeStyles((theme) => ({
  rates: {
    width: '100%',
    margin: '0.3em',
    padding: '0.5em',
  },
  deleteConceptRateButton: {
    color: theme.palette.error.main,
  },
}));

const validateRequired = [required()];

interface EditWorkerButtonProps {
  onClick?: () => void;
  disabled: boolean;
}
const EditWorkerButton = ({ onClick, disabled }: EditWorkerButtonProps) => {
  const translate = useTranslate();

  return (
    <Tooltip title={translate('viatic.texts.edit')}>
      <IconButton onClick={onClick} disabled={disabled}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

const EDITABLE_SITUATION = ['TODO', 'PROCESS'];

export const Actions = ({ basePath, data }: EditActionsProps) => {
  const authProvider = useAuthProvider();
  const dataProvider = useContext(DataProviderContext);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();

  const [workOrder2InvoiceOpen, setWorkOrder2InvoiceOpen] = useState(false);
  const [invoiceDocumentSeriesList, setInvoiceDocumentSeriesList] = useState<Record[]>([]);
  const [customerFinalizedWorkOrderList, setCustomerFinalizedWorkOrderList] = useState<Record[]>(
    [],
  );

  const handleWorkOrderFinalizeClick = () => {
    (dataProvider as GraphQLDataProvider)
      .executeQuery('workOrderFinalize', {
        input: {
          id: data!.id,
        },
      })
      .then((response) => {
        console.log('WORK ORDER FINALIZED', response);
        notify('viatic.texts.workOrderFinalized', {
          type: 'success',
        });
        refresh();
      })
      .catch((error) => {
        console.error('ERROR in workOrderFinalize', error);
        notify(error.message, {
          type: 'error',
        });
      });
  };

  const handleWorkOrder2InvoiceClick = () => {
    Promise.all([
      dataProvider.getList('DocumentSeries', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'desc' },
        filter: { type: 'INVOICE', defaultValue: true },
      }),
      dataProvider.getList('WorkOrder', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'createdAt', order: 'asc' },
        filter: { customerId: data!.customerId, situation: 'FINALIZED' },
      }),
    ]).then((response: any) => {
      console.log('Queries RESPONSE', response);
      setInvoiceDocumentSeriesList(response[0].data);
      setCustomerFinalizedWorkOrderList(response[1].data.filter((wo: any) => wo.id !== data!.id));
      setWorkOrder2InvoiceOpen(true);
    });
  };

  const handleWorkOrder2InvoiceClose = (
    extraWorkOrdersId?: string[],
    documentSeriesId?: string,
    paymentMethod?: string,
    paymentInstrument?: string,
    date?: string,
  ) => {
    console.log(
      'WORK_ORDER_2_INVOICE',
      extraWorkOrdersId,
      documentSeriesId,
      paymentMethod,
      paymentInstrument,
      date,
    );
    setWorkOrder2InvoiceOpen(false);
    if (documentSeriesId) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('workOrderToInvoice', {
          input: {
            ids: [data!.id, ...(extraWorkOrdersId || [])],
            documentSerieId: documentSeriesId,
            date,
            paymentMethod,
            paymentInstrument,
          },
        })
        .then((response) => {
          console.log('INVOICE CREATED', response);
          redirect('edit', '/Invoice', response.workOrderToInvoice.id);
        })
        .catch((error) => {
          console.error('ERROR in workOrderToInvoice', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleDownloadClick = () => {
    downloadFile(
      `${config.documentsBaseURL.WORKORDER}/${data!.document.path}`,
      `${data!.format}.pdf`,
      authProvider.getAuthorizationHeader(),
    ).catch((error) => {
      notify((error as Error).message, {
        type: 'error',
      });
    });
  };

  return (
    <>
      <TopToolbar>
        <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<CheckIcon />}
          onClick={handleWorkOrderFinalizeClick}
          disabled={!data || data.situation !== 'PROCESS'}
        >
          {translate('viatic.labels.workOrderFinalize')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<DescriptionIcon />}
          onClick={handleWorkOrder2InvoiceClick}
          disabled={!data || !data.workOrderLineList || data.situation !== 'FINALIZED'}
        >
          {translate('viatic.labels.workOrder2Invoice')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleDownloadClick}
          disabled={!data || !data.document}
        >
          PDF
        </Button>
      </TopToolbar>
      <GenerateInvoiceDialog
        open={workOrder2InvoiceOpen}
        documentSeriesList={invoiceDocumentSeriesList}
        workOrderList={customerFinalizedWorkOrderList}
        onClose={handleWorkOrder2InvoiceClose}
      />
    </>
  );
};

export const WorkOrderForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const bareDataProvider = useContext(DataProviderContext);
  const { values } = useFormState();
  const notify = useNotify();
  const workOrderSeriesList = useSeriesList('WORKORDER');
  const refresh = useRefresh();
  const statusList = useDynamicOptionList('L_STATUS');
  const situationList = useDynamicOptionList('L_WORKORDER_SITUATION');

  const [editWorkerOpen, setEditWorkerOpen] = useState(false);
  const [userList, setUserList] = useState<Record[]>([]);

  useEffect(() => {
    dataProvider
      .getList('User', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'desc' },
        filter: {},
      })
      .then((response: any) => {
        console.log('User RESPONSE', response);
        setUserList(response.data);
      });
  }, [dataProvider]);

  const handleEditWorkerClick = () => {
    setEditWorkerOpen(true);
  };

  const handleEditWorkerClose = (userId?: string) => {
    console.log('EDIT_WORKER_CLOSE', userId);

    setEditWorkerOpen(false);

    if (userId) {
      const worker = userList.find((u) => u.id === userId);
      (bareDataProvider as GraphQLDataProvider)
        .executeQuery('workOrderAssignWorker', {
          input: {
            workOrderId: values.id,
            workerUserId: worker!.id,
            workerName: `${worker!.firstName} ${worker!.lastName}`,
          },
        })
        .then((response) => {
          console.log('WORKER ASSIGNED', response);
          refresh();
        })
        .catch((error) => console.error('ERROR in workOrderAssignWorker', error));
    }
  };

  const deleteWorkOrderLine = async (workOrderLineId?: Identifier) => {
    if (workOrderLineId) {
      try {
        await dataProvider.delete('WorkOrderLine', { id: workOrderLineId });
        refresh();
      } catch (error) {
        notify((error as Error).message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.WorkOrder.fields.customer"
              source="customerId"
              reference="Customer"
              filterToQuery={(text: string) => ({ search: text })}
              validate={validateRequired}
            >
              <AutocompleteInput
                optionText={(r: any) =>
                  r && `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
                }
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Customer/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/WorkOrder',
                    state: { record: { ...values } },
                    fieldName: 'customerId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.WorkOrder.fields.deceased"
              source="deceasedId"
              reference="Deceased"
              filterToQuery={(text: string) => ({ search: text })}
              allowEmpty={true}
            >
              <AutocompleteInput
                optionText={(r: any) => (r && r.id ? `${r.name} - ${r.deathDate}` : '')}
                allowEmpty={true}
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Deceased/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/WorkOrder',
                    state: { record: { ...values } },
                    fieldName: 'deceasedId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectInput
            label="resources.Budget.fields.documentSerie"
            source="documentSerieId"
            optionText={(r: any) => `${r.year} / ${r.code}`}
            choices={workOrderSeriesList}
            defaultValue={workOrderSeriesList.find((ele) => ele.defaultValue)!.id}
            disabled={mutationType === 'update'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DateInput source="date" resource="WorkOrder" fullWidth />
        </Grid>
        {mutationType === 'update' && (
          <>
            <Grid item xs={12} md={2}>
              <TextInput source="format" resource="WorkOrder" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="situation"
                resource="WorkOrder"
                choices={situationList}
                defaultValue={situationList.find((ele) => ele.default)?.id}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInput
                source="workerName"
                resource="WorkOrder"
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <EditWorkerButton
                      onClick={handleEditWorkerClick}
                      disabled={!EDITABLE_SITUATION.includes(values.situation)}
                    />
                  ),
                }}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      {mutationType === 'update' && (
        <>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="status"
                resource="WorkOrder"
                choices={statusList}
                defaultValue={statusList.find((ele) => ele.default)!.id}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <Grid item container spacing={1}>
                <Paper elevation={1} className={classes.rates}>
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={11}>
                      <Typography variant="h6">{translate('viatic.labels.lines')}</Typography>
                    </Grid>
                    <Grid item xs={1} container direction="row-reverse">
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to={{
                          pathname: '/WorkOrderLine/create',
                          state: {
                            record: {
                              workOrderId: formData.id,
                            },
                          },
                        }}
                        disabled={!EDITABLE_SITUATION.includes(formData.situation)}
                      >
                        {translate('ra.action.create')}
                      </Button>
                    </Grid>
                  </Grid>
                  <ReferenceManyField
                    label="viatic.labels.lines"
                    target="workOrderId"
                    reference="WorkOrderLine"
                    sort={{ field: 'number', order: 'ASC' }}
                    perPage={10000}
                  >
                    <Datagrid>
                      <TextField
                        source="description"
                        resource="WorkOrderLine"
                        label={translate('resources.Concept.name', { smart_count: 1 })}
                      />
                      <EditButton disabled={!EDITABLE_SITUATION.includes(formData.situation)} />
                      <DeleteListItem
                        deleteItem={deleteWorkOrderLine}
                        disabled={!EDITABLE_SITUATION.includes(formData.situation)}
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </Paper>
                <EditWorkerDialog
                  open={editWorkerOpen}
                  onClose={handleEditWorkerClose}
                  userList={userList}
                  currentUserId={formData.workerUserId}
                />
              </Grid>
            )}
          </FormDataConsumer>
        </>
      )}
    </>
  );
};
