import React from 'react';
import { DeleteButton, Edit, ResourceComponentPropsWithId, SaveButton, SimpleForm, Toolbar, ToolbarProps } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, ConceptRateForm } from './Common';

const redirect = (basePath: string, id: string|number, data: any) => `/Rate/${data.rateId}`;

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
)

const ConceptRateEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update" redirect={redirect} toolbar={<CustomToolbar />}>
        <ConceptRateForm />
      </CustomForm>
    </Edit>
  );
};

export default ConceptRateEdit;
