import React from 'react';
import { DeleteButtonProps, getListControllerProps, Identifier, useTranslate } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  deleteListItem: {
    color: theme.palette.error.main,
  },
}));

const DeleteListItem = ({
  record,
  deleteItem,
  disabled,
}: DeleteButtonProps & { deleteItem: (id?: Identifier) => void }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Button
      variant="text"
      className={classes.deleteListItem}
      startIcon={<DeleteIcon />}
      onClick={() => {
        deleteItem(record?.id);
      }}
      disabled={disabled}
    >
      {translate('viatic.texts.delete')}
    </Button>
  );
};

export default DeleteListItem;
