import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { DataProviderContext, useTranslate } from 'react-admin';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default ({ onPeriodChange }: { onPeriodChange: (newPeriodId: string) => void }) => {
  const dataProvider = useContext(DataProviderContext);
  const translate = useTranslate();

  const [period, setPeriod] = useState('');
  const [periodList, setPeriodList] = useState<{ value: string; label: string }[]>();

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    const newPeriod = e.target.value as string;
    setPeriod(newPeriod);
    onPeriodChange(newPeriod);
  };

  useEffect(() => {
    dataProvider
      .getList('Period', {
        filter: {},
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'current DESC, description',
          order: 'desc',
        },
      })
      .then((response: any) => {
        console.log('PERIOD LIST Response', response);
        const data: { id: string; description: string }[] = response.data;
        if (data.length > 0) {
          setPeriodList(
            data.map(({ id, description }) => ({
              value: id as string,
              label: description as string,
            })),
          );
          setPeriod(data[0].id);
          onPeriodChange(data[0].id);
        }
      });
  }, []);

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="period-filter-label">
        {translate('resources.Period.name', { smart_count: 1 })}
      </InputLabel>
      <Select
        labelId="period-filter-label"
        id="period-filter-filled"
        value={period}
        onChange={handleChange}
      >
        <MenuItem value="">{translate('viatic.texts.none')}</MenuItem>
        {periodList?.map(({ value, label }, i) => (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
