import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  DateTimeInput,
  EditActionsProps,
  ListButton,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import VisibilityToolbarButton from '../../VisibilityToolbarButton';
import dayjs from 'dayjs';

export type DeceasedActionsProps = EditActionsProps & {
  onShowAttachmentsChange?: (show: boolean) => void;
};
export const Actions = ({ basePath, onShowAttachmentsChange }: DeceasedActionsProps) => {
  const translate = useTranslate();

  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
      {onShowAttachmentsChange && (
        <VisibilityToolbarButton
          label={translate('viatic.texts.attachments')}
          initialVisibility={false}
          onVisibilityChange={onShowAttachmentsChange}
        />
      )}
    </TopToolbar>
  );
};

export const deceasedTransformFn = (data: any) => {
  console.log('DeceasedTransform', data);
  return {
    ...data,
    // ownerId: data.owner?.id,
    // beneficiaryId: data.beneficiary?.id,
  };
};

export const DeceasedForm = ({ mutationType }: MutationTypeProps) => {
  const { values } = useFormState();

  const chipTypeList = useDynamicOptionList('L_CHIP_TYPE');
  const genderList = useDynamicOptionList('L_GENDER');
  const subspeciesList = useDynamicOptionList('L_SUBESPECIES');
  const raceList = useDynamicOptionList('L_RACE');
  const deceasedCauseList = useDynamicOptionList('L_DECEASED_CAUSE');
  const judicialList = useDynamicOptionList('L_JUDICIAL');
  const deceasedTypeList = useDynamicOptionList('L_DECEASED_TYPE');
  const sanitaryTypeList = useDynamicOptionList('L_SANITARY_TYPE');
  const deceasedOriginList = useDynamicOptionList('L_DECEASED_ORIGIN');
  const deceasedDestinationList = useDynamicOptionList('L_DECEASED_DESTINATION');
  const cemeterySituationList = useDynamicOptionList('L_CEMETERY_SITUATION');
  const deceasedSituationList = useDynamicOptionList('L_DECEASED_SITUATION');
  const statusList = useDynamicOptionList('L_STATUS');
  const ashesDeliveryStatusList = useDynamicOptionList('L_ASHES_DELIVERED_STATUS');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextInput source="name" resource="Deceased" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="chipType"
            resource="Deceased"
            choices={chipTypeList}
            defaultValue={chipTypeList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextInput source="chipCode" resource="Deceased" fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <DateInput
            source="birthDate"
            resource="Deceased"
            parse={(v: string) => {
              const formatDate = v ? dayjs(v).format('YYYY-MM-DDTHH:mm:ssZZ') : null;
              return formatDate;
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DateTimeInput source="deathDate" resource="Deceased" validate={[required()]} fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="gender"
            resource="Deceased"
            choices={genderList}
            defaultValue={genderList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="subspeciesCode"
            resource="Deceased"
            choices={subspeciesList}
            defaultValue={subspeciesList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="raceCode"
            resource="Deceased"
            choices={raceList.filter((o) => o.parentListId === values.subspeciesCode)}
            defaultValue={raceList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="rootCause"
            resource="Deceased"
            choices={deceasedCauseList}
            defaultValue={deceasedCauseList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="immediateCause"
            resource="Deceased"
            choices={deceasedCauseList}
            defaultValue={deceasedCauseList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="judicial"
            resource="Deceased"
            choices={judicialList}
            defaultValue={judicialList.find((ele) => ele.default)?.id}
            validate={[required()]}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="deceasedType"
            resource="Deceased"
            choices={deceasedTypeList}
            defaultValue={deceasedTypeList.find((ele) => ele.default)?.id}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="sanitaryType"
            resource="Deceased"
            choices={sanitaryTypeList}
            defaultValue={sanitaryTypeList.find((ele) => ele.default)?.id}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="viatic.texts.customer"
            source="customerId"
            reference="Customer"
            filterToQuery={(text: string) => ({ search: text })}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && r.legalIdentifier
                  ? `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
                  : ''
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="viatic.texts.partner"
            source="partnerId"
            reference="Customer"
            filterToQuery={(text: string) => ({ search: text, type: 'VETERINARIAN' })}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && r.legalIdentifier
                  ? `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
                  : ''
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="origin"
            resource="Deceased"
            choices={deceasedOriginList}
            defaultValue={deceasedOriginList.find((ele) => ele.default)?.id}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextInput source="originDescription" resource="Deceased" fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="destination"
            resource="Deceased"
            choices={deceasedDestinationList}
            defaultValue={deceasedDestinationList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextInput source="destinationDescription" resource="Deceased" fullWidth />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            label="viatic.texts.burialUnit"
            source="burialUnitId"
            reference="BurialUnit"
            filterToQuery={(text: string) => ({ search: text })}
            allowEmpty={true}
            disabled={true}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && r.zone ? `${r.zone}-${r.yard}-${r.row}-${r.number}` : ''
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="cemeterySituation"
            resource="Deceased"
            choices={cemeterySituationList}
            defaultValue={cemeterySituationList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="initSituation"
            resource="Deceased"
            choices={deceasedSituationList}
            defaultValue={deceasedSituationList.find((ele) => ele.default)?.id}
            fullWidth
          />
        </Grid>
      </Grid>
      {mutationType !== 'create' && (
        <Grid item container spacing={1}>
          <Grid item xs={12} md={3}>
            <SelectInput
              source="status"
              resource="Deceased"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)?.id}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectInput
              source="situation"
              resource="Deceased"
              choices={deceasedSituationList}
              defaultValue={deceasedSituationList.find((ele) => ele.default)?.id}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              source="ashesDeliveredStatus"
              resource="Deceased"
              choices={ashesDeliveryStatusList}
              defaultValue={ashesDeliveryStatusList.find((ele) => ele.default)?.id}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput source="ashesDeliveredName" resource="Deceased" fullWidth />
          </Grid>
        </Grid>
      )}
    </>
  );
};
