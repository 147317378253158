import { CustomError } from 'ts-custom-error';

class ValidationError extends CustomError {
  constructor(
    public readonly message: string,
    public readonly fields: { [fieldName: string]: string },
  ) {
    super(message);
    // Force error name after transpilation.
    Object.defineProperty(this, 'name', { value: 'ValidationError' });
  }
}

export default ValidationError;
