import React, { useEffect, useState } from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, InvoiceLineForm } from './Common';

const ConceptRateCreate: React.FC = (props: any) => {
  const [invoiceId, setInvoiceId] = useState<string>();

  useEffect(() => {
    setInvoiceId(props.location.state.record.invoiceId);
  }, []);

  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm
        mutationType="create"
        redirect={() => (invoiceId ? `/Invoice/${invoiceId}` : '/Invoice')}
      >
        <InvoiceLineForm />
      </CustomForm>
    </Create>
  );
};

export default ConceptRateCreate;
