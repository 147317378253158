import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import crypto from 'crypto';

import GlobalLoading from './GlobalLoading';

type Oauth2LoginProps = {
  authorizationEndpoint: string;
  tokenEndpoint: string;
  clientId: string;
  redirectURI: string;
  scope: string;
  state?: any;
  onLogin: (params: any) => Promise<any>;
};

const base64URLEncode = (str: Buffer) => {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

const randomString = (size = 21) => {
  return base64URLEncode(crypto.randomBytes(size)).slice(0, size);
};

const sha256 = (buffer: Buffer) => {
  return crypto.createHash('sha256').update(buffer).digest();
};

const Oauth2Login = ({
  authorizationEndpoint,
  clientId,
  redirectURI,
  scope,
  state,
  onLogin,
}: Oauth2LoginProps) => {
  const [authenticating, setAuthenticating] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log('Loading login form', window.location.href);
    const { origin, searchParams, hash } = new URL(window.location.href);
    const code = searchParams.get('code');
    if (!code) {
      // First time execution we have to redirect to oauth2 server.
      const state = randomString(20);
      const codeVerifier = base64URLEncode(crypto.randomBytes(32));
      const codeChallenge = base64URLEncode(sha256(Buffer.from(codeVerifier, 'utf8')));
      localStorage.setItem('state', state);
      localStorage.setItem('codeVerifier', codeVerifier);
      const authURL = `${authorizationEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&state=${state}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=S256${hash}`;
      console.log('Redirecting to ', authURL);
      window.location.href = authURL;
    } else {
      // We are returning from authorization endpoint
      const state = localStorage.getItem('state');
      localStorage.removeItem('state');
      const returnedState = searchParams.get('state');
      if (state !== returnedState) {
        setErrorMessage('Error: Wrong state received.');
      }
      const codeVerifier = localStorage.getItem('codeVerifier');
      localStorage.removeItem('codeVerifier');
      console.log('Hash: ', hash);
      window.history.replaceState(state || null, '', `${origin}/${hash !== '#/login' ? hash : ''}`);
      onLogin({
        code,
        verifier: codeVerifier,
      }).catch((error) => {
        setErrorMessage(error.message);
        setAuthenticating(false);
      });
    }
  }, []);

  return (
    <div>
      {authenticating ? (
        <GlobalLoading size={150} message={'Autenticando...'} />
      ) : (
        <div>
          <Typography component="h1" variant="h2" align="center" color="error" noWrap>
            Error en autenticación
          </Typography>
          <Typography variant="h5" align="center" color="error" paragraph>
            {errorMessage}
          </Typography>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                //onClick={handleLogin}
              >
                Reintentar login
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Oauth2Login;
