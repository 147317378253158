import React, { useState } from 'react';
import {
  DashboardMenuItem,
  getResources,
  Menu,
  MenuItemLink,
  MenuProps,
  ReduxState,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';

import SubMenu from './Submenu';

import DefaultIcon from '@material-ui/icons/ViewList';
import UsersIcon from '@material-ui/icons/AccountBox';
import ConfigIcon from '@material-ui/icons/Settings';

type MenuName = 'menuUser' | 'menuConfig';

const CustomMenu = (props: MenuProps) => {
  const [state, setState] = useState({
    menuUser: true,
    menuConfig: true,
  });
  const translate = useTranslate();
  const resources = useSelector(getResources);
  const open = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen);

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const resourceIcon = (name: string) => {
    const resource = resources.find((rs: any) => rs.name === name);
    return resource && resource.icon ? <resource.icon /> : <DefaultIcon />;
  };

  return (
    <Menu {...props}>
      {/* <DashboardMenuItem /> */}
      <MenuItemLink
        to={{
          pathname: '/Customer',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.Customer.name', { smart_count: 2 })}
        leftIcon={resourceIcon('Customer')}
      />
      <MenuItemLink
        to={{
          pathname: '/Deceased',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.Deceased.name', { smart_count: 2 })}
        leftIcon={resourceIcon('Deceased')}
      />
      <MenuItemLink
        to={{
          pathname: '/BurialUnit',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.BurialUnit.name', { smart_count: 2 })}
        leftIcon={resourceIcon('BurialUnit')}
      />
      <MenuItemLink
        to={{
          pathname: '/Attachment',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.Attachment.name', { smart_count: 2 })}
        leftIcon={resourceIcon('Attachment')}
      />
      <MenuItemLink
        to={{
          pathname: '/Budget',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.Budget.name', { smart_count: 2 })}
        leftIcon={resourceIcon('Budget')}
      />
      <MenuItemLink
        to={{
          pathname: '/WorkOrder',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.WorkOrder.name', { smart_count: 2 })}
        leftIcon={resourceIcon('WorkOrder')}
      />
      <MenuItemLink
        to={{
          pathname: '/Invoice',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('resources.Invoice.name', { smart_count: 2 })}
        leftIcon={resourceIcon('Invoice')}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuUser')}
        isOpen={state.menuUser}
        name="viatic.texts.users"
        icon={<UsersIcon />}
        dense={false}
      >
        <MenuItemLink
          to={{
            pathname: '/User',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.User.name', { smart_count: 2 })}
          leftIcon={resourceIcon('User')}
        />
        <MenuItemLink
          to={{
            pathname: '/Role',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Role.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Role')}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuConfig')}
        isOpen={state.menuConfig}
        name="viatic.texts.config"
        icon={<ConfigIcon />}
        dense={false}
      >
        <MenuItemLink
          to={{
            pathname: '/Company',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Company.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Company')}
        />
        <MenuItemLink
          to={{
            pathname: '/Oven',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Oven.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Oven')}
        />
        <MenuItemLink
          to={{
            pathname: '/DocumentSeries',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.DocumentSeries.name', { smart_count: 2 })}
          leftIcon={resourceIcon('DocumentSeries')}
        />
        <MenuItemLink
          to={{
            pathname: '/Rate',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Rate.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Rate')}
        />
        <MenuItemLink
          to={{
            pathname: '/Concept',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Concept.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Concept')}
        />
        <MenuItemLink
          to={{
            pathname: '/Period',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Period.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Period')}
        />
        <MenuItemLink
          to={{
            pathname: '/Trigger',
            state: { _scrollToTop: true },
          }}
          primaryText={translate('resources.Trigger.name', { smart_count: 2 })}
          leftIcon={resourceIcon('Trigger')}
        />
      </SubMenu>
    </Menu>
  );
};

export default CustomMenu;
