// i18nProvider.ts
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import spanishMessages from '@blackbox-vision/ra-language-spanish';

const messages: { [locale: string]: any } = {
  en: {
    ...englishMessages,
    resources: {
      Oven: {
        fields: {
          i18nDescriptions: 'Description',
        },
      },
    },
    viatic: {
      errors: {
        userNotLoggedIn: 'User not logged in',
        sessionExpired: 'User session expired',
        generalConnect: 'Error connecting to server, contact your support.',
      },
      labels: {
        assignWorker: 'Assign worker',
        back: 'Back',
        beneficiary: 'Beneficiary',
        budget2Invoice: 'Create invoice',
        budget2WorkOrder: 'Create WO',
        budgetAccept: 'Accept budget',
        cancel: 'Cancel',
        changePassword: 'Change Password',
        concept: 'Concept',
        date: 'Date',
        downloadFile: 'Descargar Fichero',
        extraWorkOrders: 'Extra work orders to invoice',
        generatedConcept: 'Generated concept',
        generatorConcept: 'Generator concept',
        i18nDescriptions: 'Description',
        invoiceCancel: 'Cancel invoice',
        invoiceRefund: 'Refund invoice',
        invoiceRectify: 'Rectify invoice',
        invoicePaid: 'Put paid',
        lines: 'Lines',
        owner: 'Owner',
        parentRate: 'Parent rate',
        period: 'Period',
        rate: 'Rate',
        rates: 'Rates',
        rol: 'Rol',
        save: 'Save',
        search: 'Search',
        series: 'Series',
        state: 'State',
        workOrder2Invoice: 'Create invoice',
        workOrderFinalize: 'Finalize work order',
      },
      texts: {
        attachment: 'attachment',
        attachments: 'Attachments',
        address: 'Address',
        beneficiary: 'Beneficiary',
        budget2Invoice: 'Create invoice',
        budget2WorkOrder: 'Create work order',
        budgetAccepted: 'Budget accepted',
        burialUnit: 'Burial unit',
        changesSaved: 'Changes saved successfully',
        config: 'Config',
        copyLegalAddress: 'Copy legal address',
        customer: 'Customer',
        deceased: 'Deceased',
        delete: 'delete',
        edit: 'Edit',
        editWorker: 'Assign worker',
        emptyRate: 'None',
        file: 'File',
        invoiceCancel: 'Generate cancelling invoice',
        invoicePaid: 'Put invoice as paid',
        invoiceRefund: 'Refund invoice',
        invoiceRectify: 'Generate rectifying invoice',
        legalAddress: 'Legal Address',
        none: 'None',
        owner: 'Owner',
        partner: 'Veterinarian',
        postalAddress: 'Postal Address',
        translations: 'Translations',
        users: 'Users',
        worker: 'Worker',
        workOrder2Invoice: 'Create invoice',
        workOrderFinalized: 'Work order finalized',
      },
    },
  },
  es: {
    ...spanishMessages,
    resources: {
      Attachment: {
        name: 'Anexo |||| Anexos',
        fields: {
          type: 'Tipo',
          customer: 'Cliente',
          deceased: 'Fallecido',
          burialUnit: 'Unidad de enterramiento',
          description: 'Descripción',
          status: 'Estado',
        },
      },
      Budget: {
        name: 'Presupuesto |||| Presupuestos',
        fields: {
          customer: 'Cliente',
          legalNameCustomer: 'Nombre Legal Cliente',
          deceased: 'Fallecido',
          paymentMethod: 'Método de pago',
          paymentInstrument: 'Instrumento de pago',
          documentSerie: 'Serie',
          date: 'Fecha',
          format: 'Num. presupuesto',
          currency: 'Moneda',
          situation: 'Situación',
          status: 'Estado',
        },
      },
      BudgetLine: {
        name: 'Linea de presupuesto |||| Lineas de presupuesto',
        fields: {
          concept: 'Concepto',
          units: 'Unidades',
          amountUnit: 'Precio unidad',
          amountSubtotal: 'Subtotal',
          amountTax: 'Impuestos',
          amountTotal: 'Total',
          startDate: 'Fecha inicio',
          endDate: 'Fecha fin',
          oven: 'Horno',
          originBurialUnit: 'Unidad enterramiento origen',
          destinationBurialUnit: 'Unidad enterramiento destino',
          originCemetery: 'Cementerio origen',
          destinationCemetery: 'Cementerio destino',
          format: 'Num. presupuesto',
          status: 'Estado',
          address: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
        },
      },
      BurialUnit: {
        name: 'Unidad de enterramiento |||| Unids. enterramiento',
        fields: {
          type: 'Tipo',
          situation: 'Situación',
          typeName: 'Nombre Tipo',
          cemetery: 'Cementerio',
          zone: 'Zona',
          yard: 'Franja',
          block: 'Bloque',
          row: 'Fila',
          number: 'Número',
          status: 'Estado',
        },
      },
      Company: {
        name: 'Empresa |||| Empresas',
        fields: {
          legalName: 'Nombre legal',
          comercialName: 'Nombre comercial',
          identifierType: 'Tipo de identificación',
          legalIdentifier: 'Identificación legal',
          email: 'Email',
          phone: 'Teléfono',
          legalAddress: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
          postalAddress: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
          status: 'Estado',
        },
      },
      Concept: {
        name: 'Concepto |||| Conceptos',
        fields: {
          type: 'Tipo',
          description: 'Descripción',
          requiresStartDate: 'Fecha inicio requerida',
          requiresEndDate: 'Fecha fin requerida',
          requiresOriginBurialUnit: 'Origen UE requerida',
          requiresDestinationBurialUnit: 'Destino UE requerida',
          requiresAddress: 'Direccion requerida',
          requiresOven: 'Horno requerido',
          requiresOriginCemetery: 'Cementerio origen requerido',
          requiresDestinationCemetery: 'Cementerio destino requerido',
          i18nDescriptions: 'Descripción',
          status: 'Estado',
        },
      },
      ConceptRate: {
        name: 'Valoracion |||| Valoraciones',
        fields: {
          amount: 'Precio',
          rateType: 'Tipo de valoración',
          invoicingIntervalTime: 'Intervalo de tiempo a facturar',
          minimalIntervalTime: 'Mínimo tiempo a facturar',
          unitTime: 'Unidad de tiempo',
          roundTypeTime: 'Como redondeamos el tiempo',
          tax: 'Impuesto',
        },
      },
      Customer: {
        name: 'Cliente |||| Clientes',
        fields: {
          firstName: 'Nombre o razón social',
          lastName: 'Apellidos',
          identifierType: 'Tipo de identificación',
          legalIdentifier: 'Identificación legal',
          legalForm: 'Forma legal',
          email: 'Email',
          phone1: 'Teléfono 1',
          phone2: 'Teléfono 2',
          language: 'Idioma',
          legalAddress: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
          postalAddress: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
          type: 'Tipo',
          status: 'Estado',
        },
      },
      Deceased: {
        name: 'Fallecido |||| Fallecidos',
        fields: {
          name: 'Nombre',
          chipType: 'Tipo de chip',
          chipCode: 'Código de chip',
          gender: 'Género',
          subspeciesCode: 'Subespecie',
          raceCode: 'Raza',
          birthDate: 'Fecha nacimiento',
          deathDate: 'Fecha fallecimiento',
          rootCause: 'Causa principal',
          immediateCause: 'Causa inmediata',
          judicial: 'Judicial',
          deceasedType: 'Tipo fallecimiento',
          sanitaryType: 'Tipo muerte sanitaria',
          origin: 'Procedencia',
          originDescription: 'Descripción procedencia',
          destination: 'Destino',
          destinationDescription: 'Descripción destino',
          cemeterySituation: 'Situación en cementerio',
          initSituation: 'Situación inicial',
          situation: 'Situación',
          ashesDeliveredStatus: 'Cenizas entregadas',
          ashesDeliveredName: 'Persona que recoge las cenizas',
          status: 'Estado',
        },
      },
      DocumentSeries: {
        name: 'Serie |||| Series',
        fields: {
          type: 'Tipo',
          year: 'Año',
          code: 'Código',
          defaultValue: 'Valor por defecto',
          format: 'Formato',
          status: 'Estado',
        },
      },
      Invoice: {
        name: 'Factura |||| Facturas',
        fields: {
          customer: 'Cliente',
          legalNameCustomer: 'Nombre Legal Cliente',
          deceased: 'Fallecido',
          paymentMethod: 'Método de pago',
          paymentInstrument: 'Instrumento de pago',
          documentSerie: 'Serie',
          date: 'Fecha',
          format: 'Num. factura',
          currency: 'Moneda',
          situation: 'Situación',
          status: 'Estado',
        },
      },
      InvoiceLine: {
        name: 'Linea de factura |||| Lineas de factura',
        fields: {
          concept: 'Concepto',
          units: 'Unidades',
          amountUnit: 'Precio unidad',
          amountSubtotal: 'Subtotal',
          amountTax: 'Impuestos',
          amountTotal: 'Total',
          startDate: 'Fecha inicio',
          endDate: 'Fecha fin',
          oven: 'Horno',
          originBurialUnit: 'Unidad enterramiento origen',
          destinationBurialUnit: 'Unidad enterramiento destino',
          originCemetery: 'Cementerio origen',
          destinationCemetery: 'Cementerio destino',
          format: 'Num. presupuesto',
          status: 'Estado',
          address: {
            address: 'Dirección',
            postalCode: 'Código postal',
            city: 'Localidad',
            province: 'Provincia',
            country: 'País',
          },
        },
      },
      Oven: {
        name: 'Horno |||| Hornos',
        fields: {
          description: 'Descripción',
          cemetery: 'Cementerio',
          i18nDescriptions: 'Descripción',
          status: 'Estado',
        },
      },
      Period: {
        name: 'Periodo |||| Periodos',
        fields: {
          description: 'Descripción',
          startDate: 'Fecha de inicio',
          endDate: 'Fecha de fin',
          i18nDescriptions: 'Descripción',
          status: 'Estado',
        },
      },
      Rate: {
        name: 'Tarifa |||| Tarifas',
        fields: {
          description: 'Descripción',
          i18nDescriptions: 'Descripción',
          status: 'Estado',
        },
      },
      Role: {
        name: 'Rol |||| Roles',
        fields: {
          description: 'Descripción',
          name: 'Nombre',
          scopes: 'Permisos',
        },
      },
      Trigger: {
        name: 'Generador |||| Generadores',
        fields: {
          periodicity: 'Periodicidad',
          daysToFirstExecution: 'Dias hasta primera ejecución',
          maxExecutions: 'Máximo de ejecuciones',
          notes: 'Notas',
          status: 'Estado',
        },
      },
      User: {
        name: 'Usuario |||| Usuarios',
        fields: {
          email: 'Email',
          firstName: 'Nombre',
          lastName: 'Apellidos',
          locked: 'Bloqueado',
          badTries: 'Intentos erróneos',
        },
      },
      WorkOrder: {
        name: 'Parte de Trabajo |||| Partes de Trabajo',
        fields: {
          customer: 'Cliente',
          legalNameCustomer: 'Nombre Legal Cliente',
          deceased: 'Fallecido',
          documentSerie: 'Serie',
          date: 'Fecha',
          format: 'Num. parte',
          situation: 'Situación',
          status: 'Estado',
          workerName: 'Trabajador',
        },
      },
    },
    viatic: {
      errors: {
        userNotLoggedIn: 'Usuario no autenticado',
        sessionExpired: 'Sesión de usuario expirada',
        generalConnect: 'Error conectando con el servidor contacte con su servicio de soporte.',
      },
      labels: {
        back: 'Atrás',
        beneficiary: 'Beneficiario',
        budgetAccept: 'Aceptar presupuesto',
        budget2Invoice: 'Crear factura',
        budget2WorkOrder: 'Crear parte',
        cancel: 'Cancel',
        changePassword: 'Cambiar contraseña',
        concept: 'Concepto',
        date: 'Fecha',
        downloadFile: 'Descargar Fichero',
        extraWorkOrders: 'Partes extra a facturar',
        generatedConcept: 'Concepto generado',
        generatorConcept: 'Concepto generador',
        i18nDescriptions: 'Description',
        invoiceCancel: 'Anular factura',
        invoicePaid: 'Marcar pagada',
        invoiceRefund: 'Devolver factura',
        invoiceRectify: 'Rectificar factura',
        lines: 'Líneas',
        owner: 'Titular',
        parentRate: 'Tarifa padre',
        period: 'Periodo',
        rate: 'Tarifa',
        rates: 'Valoraciones',
        requiresAddress: 'Dirección req.',
        requiresDestinationBurialUnit: 'UE destino req.',
        requiresEndDate: 'Fecha Fin Req.',
        requiresOriginBurialUnit: 'UE origen req.',
        requiresOven: 'Horno req.',
        requiresStartDate: 'Fecha Inicio req.',
        rol: 'Role',
        save: 'Guardar',
        search: 'Buscar',
        series: 'Serie',
        state: 'Estado',
        workOrder2Invoice: 'Crear factura',
        workOrderFinalize: 'Finalizar Parte',
      },
      texts: {
        associatedResourceValidationError:
          'Se requiere un cliente, fallecido o unidad de enterramiento',
        attachment: 'anexo',
        attachments: 'Anexos',
        address: 'Dirección',
        beneficiary: 'Beneficiario',
        budgetAccepted: 'Presupuesto aceptado',
        budget2Invoice: 'Crear factura',
        budget2WorkOrder: 'Crear parte de trabajo',
        burialUnit: 'Unidad de enterramiento',
        changesSaved: 'Cambios guardados correctamente',
        config: 'Configuración',
        copyLegalAddress: 'Copiar dirección legal',
        customer: 'Cliente',
        deceased: 'Fallecido',
        delete: 'eliminar',
        edit: 'Editar',
        editWorker: 'Asignar trabajador',
        emptyRate: 'Ninguna',
        file: 'Fichero',
        invoiceCancel: 'Generar factura anulativa',
        invoicePaid: 'Marcar factura como pagada',
        invoiceRefund: 'Devolver factura',
        invoiceRectify: 'Generar factura rectificativa',
        legalAddress: 'Dirección Legal',
        none: 'Ninguno',
        owner: 'Titular',
        partner: 'Veterinario',
        postalAddress: 'Dirección Postal',
        raceEmpty: 'Seleccione subespecie',
        translations: 'Traducciones',
        users: 'Usuarios',
        worker: 'Trabajador',
        workOrder2Invoice: 'Crear factura',
        workOrderFinalized: 'Parte finalizado',
      },
    },
  },
};

export const i18nProviderFactory = (initialLocale: string) =>
  polyglotI18nProvider((locale) => messages[locale], initialLocale, { allowMissing: true });
