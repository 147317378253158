import React from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, InvoiceForm } from './Common';

const InvoiceEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update">
        <InvoiceForm />
      </CustomForm>
    </Edit>
  );
};

export default InvoiceEdit;
