import React, { useEffect, useState } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  ResourceComponentPropsWithId,
  SaveButton,
  SimpleFormProps,
  Toolbar,
  ToolbarProps,
} from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, AttachmentForm, validateAssociatedResource } from './Common';

const AttachmentEdit = (props: any) => {
  const [redirectURL, setRedirectURL] = useState<string>();

  useEffect(() => {
    console.log('EDIT_ATTACHMENT', props.location?.state);
    if (props.location?.state && props.location?.state?.redirectURL) {
      setRedirectURL(props.location.state.redirectURL);
    }
  }, []);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm
        mutationType="update"
        uploadFileAttribute="file"
        validate={validateAssociatedResource}
        redirect={() => redirectURL}
      >
        <AttachmentForm />
      </CustomForm>
    </Edit>
  );
};

export default AttachmentEdit;
