import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  FunctionField,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const AttachmentFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const attachmentTypeList = useDynamicOptionList('L_ATTACHMENT_TYPE');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <SelectInput source="type" choices={attachmentTypeList} />
    </Filter>
  );
};

const AttachmentList = (props: ListProps) => {
  const attachmentTypeList = useDynamicOptionList('L_ATTACHMENT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <List {...props} filters={<AttachmentFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <SelectField source="type" choices={attachmentTypeList} />
        <TextField source="description" />
        <FunctionField
          label="viatic.texts.customer"
          render={(record: any) =>
            record.customer
              ? `${record.customer.firstName} ${record.customer.lastName || ''}`
              : undefined
          }
        />
        <TextField label="viatic.texts.deceased" source="deceased.name" />
        <FunctionField
          label="viatic.texts.burialUnit"
          render={(record: any) =>
            record.burialUnit
              ? `${record.burialUnit.zone}/${record.burialUnit.yard}/${record.burialUnit.block}/${record.burialUnit.row}/${record.burialUnit.number}`
              : undefined
          }
        />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AttachmentList;
