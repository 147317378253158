import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EmailField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const CustomerFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const customerTypeList = useDynamicOptionList('L_CUSTOMER_TYPE');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <TextInput source="firstName|op=LIKE" label="resources.Customer.fields.firstName" />
      <TextInput source="lastName|op=LIKE" label="resources.Customer.fields.lastName" />
      <TextInput
        source="legalIdentifier|op=LIKE"
        label="resources.Customer.fields.legalIdentifier"
      />
      <SelectInput source="type" choices={customerTypeList} />
      <TextInput source="email|op=LIKE" label="resources.Customer.fields.email" />
      <TextInput source="phone1|op=LIKE" label="resources.Customer.fields.phone1" />
    </Filter>
  );
};

const CustomerList = (props: ListProps) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const customerTypeList = useDynamicOptionList('L_CUSTOMER_TYPE');

  return (
    <List {...props} filters={<CustomerFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" textAlign="right" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="legalIdentifier" />
        <SelectField source="type" choices={customerTypeList} />
        <EmailField source="email" />
        <TextField source="phone1" />
        <TextField source="phone2" />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CustomerList;
