import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  BooleanField,
  NumberField,
  DateField,
  DateInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const WorkOrderFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const workOrderSituationList = useDynamicOptionList('L_WORKORDER_SITUATION');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <TextInput source="format|op=LIKE" label="resources.WorkOrder.fields.format" />
      <TextInput
        source="legalNameCustomer|op=LIKE"
        label="resources.WorkOrder.fields.legalNameCustomer"
      />
      <DateInput source="date|op=>=" label="resources.WorkOrder.fields.date" />
      <TextInput source="workerName|op=LIKE" label="resources.WorkOrder.fields.workerName" />
      <SelectInput source="situation" choices={workOrderSituationList} />
    </Filter>
  );
};

const WorkOrderList = (props: ListProps) => {
  const workOrderSituationList = useDynamicOptionList('L_WORKORDER_SITUATION');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <List
      {...props}
      filters={<WorkOrderFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="format" />
        <TextField source="legalNameCustomer" />
        <DateField source="date" />
        <TextField source="workerName" />
        <SelectField source="situation" choices={workOrderSituationList} />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default WorkOrderList;
