import React, { useEffect, useState, MouseEvent } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Confirm, Identifier, Record, useDataProvider, useNotify, useTranslate } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface AttachmentsProps {
  open: boolean;
  resource?: string;
  record?: Record;
}

function lowercaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const Attachments = ({ open, record, resource }: AttachmentsProps) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [attachmentList, setAttachmentList] = useState<Record[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmId, setConfirmId] = useState<string>();

  const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    setConfirmId(e.currentTarget.dataset.id);
    setConfirmOpen(true);
  };

  const handleConfirmationDo = async () => {
    if (confirmId) {
      try {
        await dataProvider.delete('Attachment', { id: confirmId });
        updateAttachmentList();
      } catch (error) {
        notify((error as Error).message, { type: 'error' });
      }
    }
    setConfirmOpen(false);
  };

  const handleConfirmationClose = () => {
    setConfirmOpen(false);
  };

  const updateAttachmentList = () => {
    const filterField = lowercaseFirstLetter(resource + '') + 'Id';
    if (record) {
      dataProvider
        .getList('Attachment', {
          pagination: { page: 1, perPage: 10000 },
          sort: { field: 'Description', order: 'desc' },
          filter: { [filterField]: record.id },
        })
        .then((response) => {
          setAttachmentList(response.data);
        });
    }
  };

  useEffect(() => {
    console.log('ATTACHMENT', record, resource);
    updateAttachmentList();
  }, [record, resource]);

  return (
    <>
      {open && (
        <Card style={{ marginLeft: '0.5em', width: '300px' }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h5">{translate('viatic.texts.attachments')}</Typography>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  size="small"
                  disabled={!resource || !record}
                  component={Link}
                  to={{
                    pathname: '/Attachment/create',
                    state:
                      resource && record
                        ? {
                            redirectURL: `/${resource}/${record!.id}`,
                            record: {
                              [lowercaseFirstLetter(resource + '') + 'Id']: record!.id,
                            },
                          }
                        : undefined,
                  }}
                >
                  {translate('ra.action.create')}
                </Button>
              </Grid>
            </Grid>
            <List>
              {attachmentList.length ? (
                attachmentList.map((att) => (
                  <ListItem key={att.id}>
                    <ListItemText primary={att.description} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        color="primary"
                        component={Link}
                        to={{
                          pathname: `/Attachment/${att.id}`,
                          state: {
                            redirectURL: `/${resource}/${record!.id}`,
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        data-id={att.id}
                        onClick={handleDeleteClick}
                        style={{ color: '#f44336' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary={translate('viatic.texts.none')}></ListItemText>
                </ListItem>
              )}
            </List>
            <Confirm
              isOpen={confirmOpen}
              title={translate('ra.message.delete_title', {
                name: translate('viatic.texts.attachment'),
                id: confirmId,
              })}
              content={translate('ra.message.delete_content')}
              onConfirm={handleConfirmationDo}
              onClose={handleConfirmationClose}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Attachments;
