import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  SelectField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  BooleanField,
  NumberField,
  DateField,
  DateInput,
} from 'react-admin';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const BudgetFilter = (props: any) => {
  const statusList = useDynamicOptionList('L_STATUS');
  const budgetSituationList = useDynamicOptionList('L_BUDGET_SITUATION');

  return (
    <Filter {...props}>
      <TextInput label="viatic.labels.search" source="search" alwaysOn />
      <SelectInput source="status" choices={statusList} />
      <TextInput source="format|op=LIKE" label="resources.Budget.fields.format" />
      <TextInput
        source="legalNameCustomer|op=LIKE"
        label="resources.Budget.fields.legalNameCustomer"
      />
      <DateInput source="date|op=>=" label="resources.Budget.fields.date" />
      <SelectInput source="situation" choices={budgetSituationList} />
    </Filter>
  );
};

const BudgetList = (props: ListProps) => {
  const budgetSituationList = useDynamicOptionList('L_BUDGET_SITUATION');
  const statusList = useDynamicOptionList('L_STATUS');
  console.log('BudgetList', props);

  return (
    <List
      {...props}
      filters={<BudgetFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="format" />
        <TextField source="legalNameCustomer" />
        <DateField source="date" />
        <NumberField source="total" />
        <SelectField source="situation" choices={budgetSituationList} />
        <SelectField source="status" choices={statusList} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default BudgetList;
