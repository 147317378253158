import React from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, DeceasedForm, deceasedTransformFn } from './Common';

const DeceasedCreate: React.FC = (props) => {
  console.log(props);
  return (
    <Create actions={<Actions />} {...props} transform={deceasedTransformFn}>
      <CustomForm mutationType="create">
        <DeceasedForm />
      </CustomForm>
    </Create>
  );
};

export default DeceasedCreate;
