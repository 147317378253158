import React, { useState } from 'react';
import {
  AutocompleteInput,
  EditActionsProps,
  email,
  FormDataConsumer,
  ListButton,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Button, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const useStyles = makeStyles({
  address: {
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateRequired = [required()];

export const Actions = ({ basePath }: EditActionsProps) => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <TopToolbar>
      {/* <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} /> */}
      <Button
        variant="text"
        color="primary"
        startIcon={<ChevronLeft />}
        onClick={() => history.goBack()}
      >
        {translate('viatic.labels.back')}
      </Button>
    </TopToolbar>
  );
};

export const ConceptRateForm = ({ mutationType }: MutationTypeProps) => {
  const { values } = useFormState();
  const rateTypeList = useDynamicOptionList('L_RATE_TYPE');
  const unitTimeList = useDynamicOptionList('L_UNIT_TIME');
  const roundTypeList = useDynamicOptionList('L_ROUND_TYPE_TIME');
  const statusList = useDynamicOptionList('L_STATUS');
  const taxList = useDynamicOptionList('L_TAX');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <ReferenceInput label="viatic.labels.rate" source="rateId" reference="Rate">
            <SelectInput optionText="description" fullWidth disabled />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={2}>
          <ReferenceInput label="viatic.labels.period" source="periodId" reference="Period">
            <SelectInput optionText="description" validate={validateRequired} fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            label="viatic.labels.concept"
            source="conceptId"
            reference="Concept"
            filter={
              values.periodId
                ? {
                    preparedFilter: true,
                    conditions: [
                      {
                        attribute: 'id',
                        comparator: 'notInConceptRate',
                        values: [values.rateId, values.periodId],
                      },
                    ],
                  }
                : {}
            }
            validate={validateRequired}
          >
            <SelectInput optionText="description" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="tax"
            resource="ConceptRate"
            choices={taxList}
            defaultValue={taxList.find((ele) => ele.default)!.id}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        {mutationType !== 'create' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="ConceptRate"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="rateType"
            resource="ConceptRate"
            choices={rateTypeList}
            defaultValue={rateTypeList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        {values.rateType === 'UNIT' ? (
          <Grid item xs={12} md={2}>
            <NumberInput source="amount" resource="ConceptRate" helperText="Por unidad" fullWidth />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="unitTime"
                resource="ConceptRate"
                choices={unitTimeList}
                defaultValue={unitTimeList.find((ele) => ele.default)!.id}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput
                source="amount"
                resource="ConceptRate"
                helperText="Por unidad de tiempo"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="minimalIntervalTime" resource="ConceptRate" fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="invoicingIntervalTime" resource="ConceptRate" fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="roundTypeTime"
                resource="ConceptRate"
                choices={roundTypeList}
                defaultValue={roundTypeList.find((ele) => ele.default)!.id}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
