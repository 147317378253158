import React from 'react';
import {
  DeleteButton,
  Edit,
  ResourceComponentPropsWithId,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
} from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, TriggerForm } from './Common';

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const TriggerEdit = (props: ResourceComponentPropsWithId) => {
  console.log(props);
  return (
    <Edit mutationMode="pessimistic" actions={<Actions />} {...props}>
      <CustomForm mutationType="update" toolbar={<CustomToolbar />}>
        <TriggerForm />
      </CustomForm>
    </Edit>
  );
};

export default TriggerEdit;
