import React from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, CompanyForm } from './Common';

const CompanyCreate: React.FC = (props) => (
  <Create actions={<Actions />} {...props}>
    <CustomForm mutationType="create">
      <CompanyForm />
    </CustomForm>
  </Create>
);

export default CompanyCreate;
