import React from 'react';
import {
  EditActionsProps,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput,
  FileInput,
  FileField,
  useTranslate,
  Record,
  useAuthProvider,
  useNotify,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { MutationTypeProps } from '../../CustomForm';
import { config } from '../../../helpers/config';
import { downloadFile } from '../../../helpers/transfer-file';
import BackButton from '../../BackButton';

const validateRequired = [required()];

export const validateAssociatedResource = (values: Record) => {
  if (!values.customerId && !values.deceasedId && !values.burialUnitId) {
    const message = 'viatic.texts.associatedResourceValidationError';
    return {
      customerId: message,
      deceasedId: message,
      burialUnitId: message,
    };
  }
};

export const Actions = ({ basePath, data }: EditActionsProps) => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const translate = useTranslate();

  const handleDownloadClick = () => {
    downloadFile(
      `${config.documentsBaseURL.ATTACHMENT}/${data!.path}`,
      data!.path.split('/').reverse()[0],
      authProvider.getAuthorizationHeader(),
    ).catch((error) => {
      notify((error as Error).message, {
        type: 'error',
      });
    });
  };

  return (
    <TopToolbar>
      <BackButton />
      <Button
        variant="text"
        color="primary"
        component="a"
        size="small"
        startIcon={<GetAppIcon />}
        onClick={handleDownloadClick}
        disabled={!data || !data.path}
      >
        {translate('viatic.labels.downloadFile')}
      </Button>
    </TopToolbar>
  );
};

export const AttachmentForm = ({ mutationType }: MutationTypeProps) => {
  const translate = useTranslate();
  const attachmentTypeList = useDynamicOptionList('L_ATTACHMENT_TYPE');
  const statusList = useDynamicOptionList('L_STATUS');

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="resources.Attachment.fields.customer"
            source="customerId"
            reference="Customer"
            filterToQuery={(text: string) => ({ search: text })}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={(r: any) =>
                r && r.id ? `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}` : ''
              }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="resources.Attachment.fields.deceased"
            source="deceasedId"
            reference="Deceased"
            filterToQuery={(text: string) => ({ search: text })}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={(r: any) => (r && r.id ? `${r.name} - ${r.deathDate}` : '')}
              allowEmpty={true}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="resources.Attachment.fields.burialUnit"
            source="burialUnitId"
            reference="BurialUnit"
            filterToQuery={(text: string) => ({ search: text })}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={(r: any) => (r && r.id ? `${r.zone}/${r.yard}/${r.row}/${r.number}` : '')}
              allowEmpty={true}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <SelectInput
            source="type"
            resource="Attachment"
            choices={attachmentTypeList}
            defaultValue={
              (attachmentTypeList.find((ele) => ele.default) || attachmentTypeList[0]).id
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            source="description"
            resource="Attachment"
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        {mutationType !== 'create' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="Attachment"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              allowEmpty={true}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FileInput
            source="file"
            label={translate('viatic.texts.file')}
            style={{ position: 'relative', top: -20 }}
            validate={mutationType === 'create' ? validateRequired : undefined}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </Grid>
    </>
  );
};
