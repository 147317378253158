import { gql } from '@apollo/client';

export const SCOPE_GET_LIST = `
  query ($take: Int, $skip: Int, $where: ScopeWhereInput) {
    total: aggregateScope {
      _count {
        _all
      }
    }
    scopes(take: $take, skip: $skip, where: $where) {
      id: name
      label
      description
    }
  }
`;

export const SCOPE_GET_ONE = ``;

export const SCOPE_GET_MANY = ``;

export const SCOPE_CREATE = ``;

export const SCOPE_UPDATE = ``;

export const SCOPE_DELETE = ``;
