import globalConfig from '../config.json';

let env: 'local' | 'uat' | 'prod' | 'test';

switch (process.env.REACT_APP_ENV) {
  case 'local':
  case 'uat':
  case 'prod':
  case 'test':
    env = process.env.REACT_APP_ENV;
    break;
  default:
    env = 'local';
}

export const config = globalConfig[env];

console.log('Selected config', config);
