import React, { useCallback, useState } from 'react';
import {
  FormWithRedirect,
  Toolbar,
  SimpleFormProps,
  SimpleFormViewProps,
  useMutation,
  useNotify,
  useRedirect,
  OnSuccess,
  OnFailure,
  TransformData,
  useRefresh,
  DataProviderContext,
  useAuthProvider,
  Identifier,
  Record,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { merge } from 'lodash';

import { config } from '../helpers/config';
import { uploadFile } from '../helpers/transfer-file';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export type MutationTypeProps = {
  mutationType?: 'create' | 'update';
};
export type CustomFormProps = SimpleFormProps &
  MutationTypeProps & {
    uploadFileAttribute?: string;
    customTransform?: (data: any) => any | Promise<any>;
  };
export type RedirectState = {
  redirectTo: string;
  basePath?: string;
  id?: Identifier;
  data?: Partial<Record>;
  state?: Object;
  fieldName?: string;
};

const CustomForm = ({
  children,
  mutationType,
  uploadFileAttribute,
  customTransform,
  ...props
}: CustomFormProps) => {
  const dataProvider = React.useContext(DataProviderContext);
  const authProvider = useAuthProvider();
  const location = useLocation<{ redirectInfo: RedirectState }>();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [mutate] = useMutation();

  const [redirectInfo, setRedirectInfo] = useState<RedirectState>();

  const save = useCallback(
    async (
      values: any,
      redirectTo?: any,
      callback?: { onSuccess?: OnSuccess; onFailure?: OnFailure; transform?: TransformData },
    ) => {
      console.log('CustomSave', values, redirectTo, callback);
      try {
        const data = customTransform ? await customTransform(values) : values;
        const responseData = await mutate(
          {
            type: mutationType || 'update',
            resource: props.resource,
            payload: { data },
          },
          { returnPromise: true },
        );
        if (uploadFileAttribute && values[uploadFileAttribute]) {
          await uploadFile(
            config.uploadURL,
            values[uploadFileAttribute].rawFile,
            dataProvider.getCompanyId(),
            values.id || responseData.data.id,
            authProvider.getAuthorizationHeader(),
          );
        }
        const successFn = callback?.onSuccess
          ? callback.onSuccess
          : () => {
              notify(
                mutationType === 'update' ? 'ra.notification.updated' : 'ra.notification.created',
                'info',
                { smart_count: 1 },
                props.mutationMode === 'undoable',
              );
              if (redirectInfo) {
                console.log(
                  'Custom redirect',
                  redirectInfo,
                  merge(redirectInfo.state, {
                    record: {
                      ...(redirectInfo.fieldName
                        ? { [redirectInfo.fieldName]: responseData.data.id }
                        : {}),
                    },
                  }),
                );
                redirect(
                  redirectInfo.redirectTo,
                  redirectInfo.basePath,
                  redirectInfo.id,
                  redirectInfo.data,
                  merge(redirectInfo.state, {
                    record: {
                      ...(redirectInfo.fieldName
                        ? { [redirectInfo.fieldName]: responseData.data.id }
                        : {}),
                    },
                  }),
                );
              } else {
                console.log('No custom redirect specified');
                redirect(redirectTo, props.basePath, responseData.data.id, responseData.data);
              }
            };
        successFn(responseData);
      } catch (error: any) {
        if (error.name === 'ValidationError') {
          return error.fields;
        }
        const failureFn = callback?.onFailure
          ? callback.onFailure
          : (error: any) => {
              notify(
                typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
                'warning',
                {
                  _:
                    typeof error === 'string'
                      ? error
                      : error && error.message
                      ? error.message
                      : undefined,
                },
              );
              if (
                (mutationType === 'update' && props.mutationMode === 'undoable') ||
                props.mutationMode === 'pessimistic'
              ) {
                refresh();
              }
            };
        failureFn(error);
      }
    },
    [
      mutate,
      notify,
      redirect,
      refresh,
      customTransform,
      redirectInfo,
      authProvider,
      dataProvider,
      mutationType,
      props.mutationMode,
      props.basePath,
      props.resource,
      uploadFileAttribute,
    ],
  );
  useEffect(() => {
    console.log('CUSTOM_FORM location', location.state);
    if (location.state?.redirectInfo) {
      setRedirectInfo(location.state.redirectInfo);
    }
  }, [location.state]);
  return (
    <FormWithRedirect
      {...props}
      save={save}
      render={({
        basePath,
        handleSubmitWithRedirect,
        handleSubmit,
        invalid,
        mutationMode,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        validating,
      }: SimpleFormViewProps) => (
        <form>
          <Grid container direction="column" style={{ paddingLeft: '.5em', paddingRight: '.5em' }}>
            {React.cloneElement(children as React.ReactElement<any>, { mutationType })}
          </Grid>
          {props.toolbar ? (
            React.cloneElement(props.toolbar, {
              basePath,
              handleSubmitWithRedirect,
              handleSubmit,
              invalid,
              mutationMode,
              pristine,
              record,
              redirect,
              resource,
              saving,
              submitOnEnter,
              validating,
            })
          ) : (
            <Toolbar
              {...{
                basePath,
                handleSubmitWithRedirect,
                handleSubmit,
                invalid,
                mutationMode,
                pristine,
                record,
                redirect,
                resource,
                saving,
                submitOnEnter,
                validating,
              }}
            />
          )}
        </form>
      )}
    />
  );
};

export default CustomForm;
