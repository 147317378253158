import React, { useEffect, useState } from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, BudgetLineForm } from './Common';

const ConceptRateCreate: React.FC = (props: any) => {
  const [budgetId, setBudgetId] = useState<string>();

  useEffect(() => {
    setBudgetId(props.location.state.record.budgetId);
  }, []);

  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm
        mutationType="create"
        redirect={() => (budgetId ? `/Budget/${budgetId}` : '/Budget')}
      >
        <BudgetLineForm />
      </CustomForm>
    </Create>
  );
};

export default ConceptRateCreate;
