import { gql } from '@apollo/client';

export const ROLE_GET_LIST = `
  query ($take: Int, $skip: Int, $orderBy: [RoleOrderByInput!], $where: RoleWhereInput) {
    total: aggregateRole {
      _count {
        _all
      }
    }
    roles(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_GET_ONE = `
  query ($where: RoleWhereUniqueInput!) {
    role (where: $where) {
      id
      name
      description
      scopes {
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_GET_MANY = `
  query ($where: RoleWhereInput!) {
    roles(take: 1000, orderBy: { name: asc }, where: $where) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_CREATE = `
  mutation ($data: RoleCreateInput!) {
    createRole(data: $data) {
      id
      name
      description
      createdAt
      updatedAt
      scopes {
        name
        label
        description
      }
    }
  }
`;

export const ROLE_UPDATE = `
  mutation ($data: RoleUpdateInput!, $where: RoleWhereUniqueInput!) {
    updateRole(data: $data, where: $where) {
      id
      name
      description
      createdAt
      updatedAt
      scopes {
        name
        label
        description
      }
    }
  }
`;

export const ROLE_DELETE = `
  mutation ($id: String!) {
    deleteRole(where: {id: $id}) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
