import React from 'react';
import { Create } from 'react-admin';
import CustomForm from '../../CustomForm';

import { Actions, DocumentSeriesForm } from './Common';

const DocumentSeriesCreate: React.FC = (props) => {
  return (
    <Create actions={<Actions />} {...props}>
      <CustomForm mutationType="create">
        <DocumentSeriesForm />
      </CustomForm>
    </Create>
  );
};

export default DocumentSeriesCreate;
