import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  EditActionsProps,
  ListButton,
  Record,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useDataProvider,
} from 'react-admin';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';

const validateCemetery = [required()];
const validateType = [required()];
const validateZone = [required()];
const validateYard = [required()];
const validateBlock = [required()];
const validateRow = [required()];
const validateNumber = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const RoleForm = ({ mutationType }: MutationTypeProps) => {
  const dataProvider = useDataProvider();
  const [scopes, setScopes] = useState<Record[]>([]);

  useEffect(() => {
    dataProvider
      .getList('Scope', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'asc' },
        filter: {
          preparedFilter: true,
          OR: [
            {
              name: {
                contains: 'viatic:',
              },
            },
            {
              name: {
                contains: 'oauth:',
              },
            },
          ],
        },
      })
      .then(({ data }) => {
        setScopes(data);
      });
  }, []);
  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} md={3}>
        <TextInput source="name" resource="Role" validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={12} md={9}>
        <TextInput source="description" resource="Role" fullWidth />
      </Grid>
      <Grid item xs={12} md={12}>
        <AutocompleteArrayInput
          source="scopes"
          choices={scopes}
          optionText={(v: any) => `${v.label} (${v.id})`}
          parse={(value: any) =>
            value &&
            value.map((v: any) => ({
              name: v,
            }))
          }
          format={(value: any) => value && value.map((v: any) => v.name)}
        />
      </Grid>
    </Grid>
  );
};
