import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AutocompleteInput,
  DateTimeInput,
  DeleteButtonProps,
  EditActionsProps,
  email,
  FormDataConsumer,
  Identifier,
  ListButton,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useDataProvider,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { useCurrentPeriod } from '../../../contexts/CurrentPeriod';
import BackButton from '../../BackButton';

const useStyles = makeStyles({
  address: {
    width: '100%',
    margin: '0.3em',
    padding: '0.5em',
    backgroundColor: '#fafafa',
  },
});

const validateRequired = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <BackButton />
  </TopToolbar>
);

export const InvoiceLineForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const dataProvider = useDataProvider();
  const { values } = useFormState();
  const redirect = useRedirect();
  const currentInvoice = useSelector(
    (state: any) => state.admin.resources.Invoice.data[values.invoiceId],
  );

  const statusList = useDynamicOptionList('L_STATUS');
  const globalCemeteryList = useDynamicOptionList('L_GLOBAL_CEMETERY');

  const currentPeriod = useCurrentPeriod();

  const conceptTypeTranslation = _.keyBy(useDynamicOptionList('L_CONCEPT_TYPE'), 'id');

  const [conceptRateList, setConceptRateList] = useState<any[]>([]);
  const [usedConceptIds, setUsedConceptIds] = useState<string[]>([]);
  const [selectedConcept, setSelectedConcept] = useState<any>(null);

  useEffect(() => {
    console.log('INVOICE LINE STATE', currentInvoice);

    if (!currentInvoice || !currentInvoice.rate) {
      redirect('list', '/Invoice');
      return;
    }

    dataProvider
      .getList('ConceptRate', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'desc' },
        filter: {
          preparedFilter: true,
          conditions: [
            {
              attribute: 'rateId',
              comparator: '=',
              values: [
                currentInvoice.rateId,
                ...(currentInvoice.rate.parentRateId ? [currentInvoice.rate.parentRateId] : []),
              ],
            },
            {
              attribute: 'periodId',
              comparator: '=',
              values: [currentPeriod!.id],
            },
          ],
        },
      })
      .then((response: any) => {
        console.log('ConceptRate RESPONSE', response);
        const uniqueConcepts: { [key: string]: any } = {};
        for (const { concept } of response.data) {
          uniqueConcepts[concept.id] = concept;
        }
        const tempConceptRates = Object.values(uniqueConcepts);
        setConceptRateList(tempConceptRates);
        setSelectedConcept(tempConceptRates.find((cr: any) => cr.id === values.conceptId));
      });
    setUsedConceptIds(
      currentInvoice.invoiceLineList.list
        .map((invoiceLine: any) => invoiceLine.conceptId)
        .filter((conceptId: any) => conceptId !== values.conceptId),
    );
  }, []);

  const isRequired = (field: string): boolean => {
    console.log(
      'IS_REQUIRED',
      `requires${field.charAt(0).toUpperCase()}${field.slice(1)}`,
      selectedConcept &&
        selectedConcept[`requires${field.charAt(0).toUpperCase()}${field.slice(1)}`] === 'Y',
      selectedConcept,
    );
    return (
      selectedConcept &&
      selectedConcept[`requires${field.charAt(0).toUpperCase()}${field.slice(1)}`] === 'Y'
    );
  };

  if (!currentInvoice) {
    redirect('list', '/Invoice');
    return <div></div>;
  }

  return (
    <>
      <Grid item container spacing={1}>
        {mutationType === 'update' && (
          <Grid item xs={12} md={2}>
            <TextField
              label={translate('resources.InvoiceLine.fields.format')}
              value={currentInvoice.format}
              variant="filled"
              margin="dense"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {conceptRateList.length !== 0 && (
          <Grid item xs={12} md={4}>
            <AutocompleteInput
              label="resources.BudgetLine.fields.concept"
              source="conceptId"
              resource="BudgetLine"
              choices={conceptRateList
                .filter((concept: any) => !usedConceptIds.includes(concept.id))
                .sort((a: any, b: any) => a.description.localeCompare(b.description))}
              optionText={(r: any) =>
                r && `#${r.id} ${conceptTypeTranslation[r.type].name} - ${r.description}`
              }
              onChange={(conceptId: string) => {
                conceptId &&
                  setSelectedConcept(conceptRateList.find((cr: any) => cr.id === conceptId));
              }}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <NumberInput source="units" resource="InvoiceLine" defaultValue={1} fullWidth />
        </Grid>
        {mutationType === 'update' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="InvoiceLine"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        {isRequired('startDate') && (
          <Grid item xs={12} md={2}>
            <DateTimeInput
              source="startDate"
              resource="InvoiceLine"
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        )}
        {isRequired('endDate') && (
          <Grid item xs={12} md={2}>
            <DateTimeInput
              source="endDate"
              resource="InvoiceLine"
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        )}
        {isRequired('oven') && (
          <Grid item xs={12} md={2}>
            <ReferenceInput
              label="resources.InvoiceLine.fields.oven"
              source="ovenId"
              resource="InvoiceLine"
              reference="Oven"
            >
              <SelectInput optionText="description" validate={validateRequired} fullWidth />
            </ReferenceInput>
          </Grid>
        )}
        {isRequired('originBurialUnit') && (
          <Grid item xs={12} md={3}>
            <ReferenceInput
              label="resources.InvoiceLine.fields.originBurialUnit"
              source="originBurialUnitId"
              reference="BurialUnit"
              filterToQuery={(text: string) => ({ search: text })}
            >
              <AutocompleteInput
                optionText={(r: any) =>
                  r && r.zone ? `#${r.id} ${r.zone}-${r.yard}-${r.row}-${r.number}` : ''
                }
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        )}
        {isRequired('destinationBurialUnit') && (
          <Grid item xs={12} md={3}>
            <ReferenceInput
              label="resources.InvoiceLine.fields.destinationBurialUnit"
              source="destinationBurialUnitId"
              reference="BurialUnit"
              filter={{ situation: 'AVAILABLE' }}
              filterToQuery={(text: string) => ({ search: text })}
            >
              <AutocompleteInput
                optionText={(r: any) =>
                  r && r.zone ? `#${r.id} ${r.zone}-${r.yard}-${r.row}-${r.number}` : ''
                }
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        )}
        {isRequired('originCemetery') && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="originCemetery"
              resource="InvoiceLine"
              choices={globalCemeteryList}
              defaultValue={globalCemeteryList.find((ele) => ele.default)?.id}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        )}
        {isRequired('destinationCemetery') && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="destinationCemetery"
              resource="InvoiceLine"
              choices={globalCemeteryList}
              defaultValue={globalCemeteryList.find((ele) => ele.default)?.id}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      {isRequired('address') && (
        <Grid item container spacing={1}>
          <Paper elevation={1} className={classes.address}>
            <Typography variant="h6">{translate('viatic.texts.address')}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <TextInput
                  source="address.address"
                  resource="InvoiceLine"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextInput
                  source="address.postalCode"
                  resource="InvoiceLine"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextInput
                  source="address.city"
                  resource="InvoiceLine"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextInput
                  source="address.province"
                  resource="InvoiceLine"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextInput
                  source="address.country"
                  resource="InvoiceLine"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};
